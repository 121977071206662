import Navbar from "../Components/Navbar";
import AboutContent from "../Components/AboutContent";
import CompanyValues from "../Components/CompanyValues";
import Footer from "../Components/Footer";
import ScrollUp from "../Components/ScrollUp";

const About = () => {
  return (
    <div>
      <Navbar />
      <AboutContent/>
      <CompanyValues/>
      <Footer/>
      <ScrollUp/>
    </div>
  );
};

export default About;
