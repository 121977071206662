import fisrtCardImg from "../Assets/Images/Untitled.png";
import fourthCardImg from "../Assets/Images/Artifical_Intelligence.png";
import fifthCardImg from "../Assets/Images/Web-Develop.png";
import sixthCardImg from "../Assets/Images/Mobile_App.png";
import { Link } from "react-router-dom";

const ServicesCards = () => {
  return (
    <div className="max-w-[1140px] mx-auto lg:py-28 md:py-20 max-sm:py-10 p-4">
      <div>
        <h1 data-aos="fade-up" className="text-4xl font-bold">
          <span className="border-b-4 border-black">Our</span> Services
        </h1>
        <p className="py-6 text-lg text-gray-500">
          In addition to Blockchain technology development, we provide a wide
          range of services to meet your business needs effectively. Our
          expertise extends to custom Web3 services, Artificial Intelligence,
          Staff Augmentation, UI/UX Design, and Digital Marketing. By leveraging
          these services, we can help your business generate leads and drive
          sales.
        </p>
      </div>
      <div className="grid place-items-center lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-4">
        <div className="p-6  bg-black hover:bg-customRed border shadow">
          <div className="flex justify-center">
            <img className="h-28" src={fisrtCardImg} alt="" />
          </div>
          <h5 className="mb-2 text-[28px] font-bold tracking-tight text-white text-center">
            Metaverse
          </h5>
          <p className="mb-3 font-normal text-white text-center text-lg">
            Metaverse is a multidimensional digital space that lies beyond the
            world we live in. It’s a collection of all possible versions of a
            place or object, along with our perception of ownership and rights
            over them.
          </p>
          <Link
            to="/metaverse"
            className="flex items-center px-3 py-2 text-sm font-medium text-center text-white justify-center"
          >
            Read more
            <svg
              className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </Link>
        </div>
        <div className="p-6 bg-black hover:bg-customRed border shadow">
          <div className="flex justify-center">
            <img className="h-28" src={fourthCardImg} alt="" />
          </div>
          <h5 className="mb-2 pt-6 text-[28px] font-bold tracking-tight text-white text-center">
            Artificial Intelligence
          </h5>
          <p className="mb-3 font-normal text-white text-center text-lg">
            Artificial intelligence is the future. Take your business to the
            next level as we develop AI applications for various industries from
            healthcare & forecasting
          </p>
          <Link
            to="/artificialIntellegence"
            className="flex items-center px-3 py-2 text-sm font-medium text-center text-white justify-center"
          >
            Read more
            <svg
              className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </Link>
        </div>
        <div className="p-6 bg-black hover:bg-customRed border shadow">
          <div className="flex justify-center">
            <img className="h-36" src={fifthCardImg} alt="" />
          </div>
          <h5 className="mb-2 text-[28px] font-bold tracking-tight text-white text-center">
            Web Development
          </h5>
          <p className="mb-3 font-normal text-white text-center text-lg">
            We provides exceptional web development services to help businesses
            worldwide establish a powerful online presence.
          </p>
          <Link
            to="/webdev"
            className="flex items-center px-3 py-2 text-sm font-medium text-center text-white justify-center"
          >
            Read more
            <svg
              className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </Link>
        </div>
        <div className="p-6 bg-black hover:bg-customRed border shadow">
          <div className="flex justify-center">
            <img className="h-28" src={sixthCardImg} alt="" />
          </div>
          <h5 className="mb-2 text-[28px] font-bold tracking-tight text-white text-center">
            Mobile App Development
          </h5>
          <p className="mb-3 font-normal text-white text-center text-lg">
            We offer high-end mobile app development services ranging from
            custom iOS, Android apps, React Native app and Flutter app
            development.
          </p>
          <Link
            to="/appdev"
            className="flex items-center px-3 py-2 text-sm font-medium text-center text-white justify-center"
          >
            Read more
            <svg
              className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ServicesCards;
