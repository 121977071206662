import VisionImg from "../Assets/Images/Vission.svg";
import MissionImg from "../Assets/Images/mission.svg";
import ValuesImg from "../Assets/Images/values.svg";

const CompanyValues = () => {
  return (
    <>
      <div className="bg-gray-100 py-2">
        <div className="max-w-[1140px] mx-auto my-16 p-4">
          <div>
            <h1 className="lg:text-5xl md:text-3xl font-bold text-center">
              Our Company Values
            </h1>
            <p className="py-6 text-lg text-gray-500 text-center">
              We have strong values which we abide by.
            </p>
          </div>
          <div className="grid place-items-center lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-4">
            <div className="max-w-sm p-6  bg-white border shadow">
              <div className="flex justify-center">
                <img className="h-28" src={VisionImg} alt="" />
              </div>
              <h5 className="mb-2 text-[28px] font-bold tracking-tight py-3  text-black text-center">
                VISION
              </h5>
              <p className="mb-3 font-normal text-gray-400 text-center text-lg">
                At SpaceX, we envision a future where cutting-edge technological
                solutions seamlessly integrate into every facet of business
                operations. Our commitment lies in empowering enterprises to
                embrace the transformative potential of blockchain technology,
                revolutionizing their business processes and unlocking new
                opportunities for growth and innovation.
              </p>
            </div>
            <div className="max-w-sm p-6 bg-white border shadow">
              <div className="flex justify-center">
                <img className="h-28" src={MissionImg} alt="" />
              </div>
              <h5 className="mb-2 text-[28px] font-bold tracking-tight py-3 text-black text-center">
                MISSION
              </h5>
              <p className="mb-3 font-normal text-gray-400 text-center text-lg">
                At SpaceX, our mission is clear: to deliver unparalleled
                excellence through premium services that consistently exceed
                customer expectations. Through our relentless pursuit of
                innovation, we empower our clients to realize their business
                objectives, leveraging cutting-edge technologies to drive
                success and foster growth in an ever-evolving digital landscape.
              </p>
            </div>
            <div className="max-w-sm p-6 bg-white border shadow">
              <div className="flex justify-center">
                <img className="h-28" src={ValuesImg} alt="" />
              </div>
              <h5 className="mb-2 text-[28px] font-bold tracking-tight py-3 text-black text-center">
                VALUES
              </h5>
              <p className="mb-3 font-normal  text-gray-400 text-center text-lg">
                At SpaceX, our values are the cornerstone of our success. We
                pride ourselves on delivering innovative solutions that not only
                meet but exceed the expectations of our clients, fostering
                long-term partnerships built on trust and mutual respect. Our
                unwavering commitment to providing tailored blockchain
                technology solutions ensures that we consistently address the
                unique needs
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyValues;
