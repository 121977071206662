import { useEffect } from "react";
import {
  FaReact,
  FaNodeJs,
  FaShopify,
  FaWordpressSimple,
} from "react-icons/fa";
import { SiDjango } from "react-icons/si";
import { LiaLaravel } from "react-icons/lia";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const CustomCards = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      offset: 200,
    });
  }, []);
  return (
    <div className="max-w-[1140px] mx-auto p-4 pt-20">
      <div>
        <h1 className="text-3xl font-bold">Custom Web Development Services</h1>
        <div data-aos="fade-right" className="border-b-4 border-black w-[70px] "></div>
        <p className="text-gray-500 text-lg py-7">
          We offer a wide range of Web Development services so that your
          business can scale.
        </p>
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-3 max-sm:place-items-center gap-5">
        <div className="max-w-sm p-6  bg-white shadow-2xl">
          <div className="flex justify-center py-8">
            <FaWordpressSimple size={50} className="text-customRed" />
          </div>
          <h5 className="mb-2 text-[18px] font-bold text-black text-center">
            WordPress Development
          </h5>
          <p className="mb-3 font-normal text-gray-500 text-center text-md">
            The majority of the websites around the globe are powered by
            WordPress. We offer effective WordPress development services for the
            most effective digital experiences.
          </p>
        </div>
        <div className="max-w-sm p-6  bg-white shadow-2xl">
          <div className="flex justify-center py-8">
            <FaShopify size={50} className="text-customRed" />
          </div>
          <h5 className="mb-2 text-[18px] font-bold text-black text-center">
            Shopify
          </h5>
          <p className="mb-3 font-normal text-gray-500 text-center text-md">
            We help enterprises of all kinds develop profitable Shopify
            eCommerce stores that have been a proven success and generated
            massive sales revenue.
          </p>
        </div>
        <div className="max-w-sm p-6  bg-white shadow-2xl">
          <div className="flex justify-center py-8">
            <FaNodeJs size={50} className="text-customRed" />
          </div>
          <h5 className="mb-2 text-[18px] font-bold text-black text-center">
            Node.js Development
          </h5>
          <p className="mb-3 font-normal text-gray-500 text-center text-md">
            We aim to meet all your business requirements as you get to work
            alongside our competent team of Node.js developers.
          </p>
        </div>
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-3 max-sm:place-items-center gap-5 my-5">
        <div className="max-w-sm p-6  bg-white shadow-2xl">
          <div className="flex justify-center py-8">
            <FaReact size={50} className="text-customRed" />
          </div>
          <h5 className="mb-2 text-[18px] font-bold text-black text-center">
            React.js Development
          </h5>
          <p className="mb-3 font-normal text-gray-500 text-center text-md">
            At SpaceX, we have built many successful projects using React. With
            our years of expertise, we have built amazing products that people
            will love.
          </p>
        </div>
        <div className="max-w-sm p-6  bg-white shadow-2xl">
          <div className="flex justify-center py-8">
            <LiaLaravel size={50} className="text-customRed" />
          </div>
          <h5 className="mb-2 text-[18px] font-bold text-black text-center">
            Laravel Development
          </h5>
          <p className="mb-3 font-normal text-gray-500 text-center text-md">
            We help you develop web apps that are fast and intuitive using the
            Laravel framework. Avail Laravel's amazing features and stellar user
            experience.
          </p>
        </div>
        <div className="max-w-sm p-6  bg-white shadow-2xl">
          <div className="flex justify-center py-8">
            <SiDjango size={50} className="text-customRed" />
          </div>
          <h5 className="mb-2 text-[18px] font-bold text-black text-center">
            Django Development
          </h5>
          <p className="mb-3 font-normal text-gray-500 text-center text-md">
            Our team of highly qualified and trained Django developers builds
            interactive and high-performing applications that fit your needs.
          </p>
        </div>
        <div className="max-w-sm p-6  bg-white shadow-2xl">
          <div className="flex justify-center py-8">
            <FaReact size={50} className="text-customRed" />
          </div>
          <h5 className="mb-2 text-[18px] font-bold text-black text-center">
            Typescript Development
          </h5>
          <p className="mb-3 font-normal text-gray-500 text-center text-md">
            Our portfolio in typescript web development speaks for itself. Avail
            our services to develop scalable websites.
          </p>
        </div>
      </div>
      {/* IMAGE CONTENT START */}
      <div className="my-16 overlay-bg py-14 p-8">
        <div className="grid lg:grid-cols-2 md:grid-cols-2 max-sm:place-items-center gap-10">
          <div>
            <h1 data-aos="fade-up" className="text-white text-3xl font-bold">
              Attract leads to your website and enhance ROI
            </h1>
          </div>
          <div className="flex justify-end items-center">
            <Link to="/contactUs">
              <button
                type="button"
                className="text-white bg-customRed cursor-pointer focus:outline-none font-bold rounded-md text-sm px-7 py-3 text-center hover:scale-110 transition ease-in-out"
              >
                Talk to Our Experts
              </button>
            </Link>
          </div>
        </div>
      </div>
      {/* IMAGE CONTENT END */}
    </div>
  );
};

export default CustomCards;
