import "../Css/Main.css";
import curveImg from "../Assets/Images/Curve.svg";
import intellegnceHome from "../Assets/Images/intellegenceHome.png";
import { Link } from "react-router-dom";

const IntellegnceHome = () => {
  return (
    <div className="bg-black pt-10 relative">
      <div className="w-[100%] absolute bg-customlighGray bottom-0">
        <img className="w-[100%]" src={curveImg} alt="" />
      </div>
      <div className="max-w-[1240px] mx-auto py-40 p-4">
        <div className="grid lg:grid-cols-2 gap-10 place-items-center">
          <div>
            <h1 className="lg:text-4xl md:text-4xl max-sm:text-[34px] font-extrabold text-white">
              ARTIFICIAL INTELLIGENCE SERVICES
            </h1>
            <p className="text-xl text-gray-400 my-5">
              Let us help you build advanced solutions that elevate your
              business. Our Artificial Intelligence (AI) Development Services
              offer a variety of AI applications, including healthcare
              analytics, virtual assistants, and forecasting tools. Automate
              your business processes with our expert AI development services.
            </p>
            <Link to="/contactUs">
              <button
                type="button"
                className="text-white my-3 bg-customRed cursor-pointer focus:outline-none font-bold rounded-md text-md px-5 py-2 hover:scale-110 transition ease-in-out text-center"
              >
                Get free Consultation
              </button>
            </Link>
          </div>
          <div>
            <img src={intellegnceHome} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntellegnceHome;
