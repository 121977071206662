import { FaMapMarked } from "react-icons/fa";

const ContactCards = () => {
  return (
    <div className="bg-customlighGray">
      <div className="max-w-[1140px] mx-auto p-4 pt-24 pb-10">
        <div className="grid lg:grid-cols-3 md:grid-cols-3 max-sm:place-items-center gap-2">
          <div className="w-[100%] p-6  bg-white shadow-2xl py-20">
            <div className="flex justify-center pb-8">
              <FaMapMarked size={50} className="text-customRed" />
            </div>
            <h5 className="mb-2 text-[26px] font-bold text-black text-center">
              USA
            </h5>
            <p className="mb-3 font-normal text-gray-500 text-center text-lg">
              1740 Innovation Dr. Suite # 210 Carbondale, IL. 62903. USA.
            </p>
          </div>
          <div className="w-[100%] p-6  bg-white shadow-2xl py-20">
            <div className="flex justify-center pb-8">
              <FaMapMarked size={50} className="text-customRed" />
            </div>
            <h5 className="mb-2 text-[26px] font-bold text-black text-center">
              Pakistan
            </h5>
            <p className="mb-3 font-normal text-gray-500 text-center text-lg">
              Local Branch:Trade Center Gujranwala,Pakistan
            </p>
          </div>
          <div className="w-[100%] p-6  bg-white shadow-2xl py-20">
            <div className="flex justify-center pb-8">
              <FaMapMarked size={50} className="text-customRed" />
            </div>
            <h5 className="mb-2 text-[26px] font-bold text-black text-center">
              info@spacexpakistan.com
            </h5>
            <p className="text-customRed hover:text-gray-500 text-center text-lg">
              info@spacexpakistan.com
            </p>
            <p className="mb-3 text-customRed hover:text-gray-500 text-center text-lg">
              +92-334-4403514
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactCards;
