import "../Css/Main.css";
import curveImg from "../Assets/Images/Curve.svg";
import DevHomeImg from "../Assets/Images/WebDevHome.png";
import { Link } from "react-router-dom";

const WebDevHome = () => {
  return (
    <div className="bg-black pt-10 relative">
      <div className="w-[100%] absolute bg-customlighGray bottom-0">
        <img className="w-[100%]" src={curveImg} alt="" />
      </div>
      <div className="max-w-[1240px] mx-auto py-40 p-4">
        <div className="grid lg:grid-cols-2 gap-10 place-items-center">
          <div>
            <h1 className="lg:text-3xl md:text-3xl max-sm:text-[34px] font-extrabold text-white">
              WEB DEVELOPMENT SERVICES
            </h1>
            <p className="text-xl text-white my-5">
              SpaceX provides exceptional web development services to help
              businesses worldwide establish a powerful online presence.
            </p>
            <p className="text-xl text-white my-5">
              In the digital era, a captivating website is paramount for brand
              success, converting visitors into loyal customers. Elevate your
              brand awareness and unlock new opportunities with our effective
              website solutions.
            </p>
            <Link to="/contactUs">
              <button
                type="button"
                className="text-white my-3 bg-customRed cursor-pointer focus:outline-none font-bold rounded-md text-md px-5 py-2 hover:scale-110 transition ease-in-out text-center"
              >
                Get free Consultaation
              </button>
            </Link>
          </div>
          <div>
            <img className="h-[490px]" src={DevHomeImg} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebDevHome;
