import ServiceImg from "../Assets/Images/ServicesImage.jpg";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const ServiceContent = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      offset: 200,
    });
  }, []);
  return (
    <div>
      <div className="max-w-[1140px] mx-auto pt-10 my-28 p-4">
        <div className="grid place-items-center lg:grid-cols-2 gap-10 md:grid-cols-1">
          <div className="p-4">
            <h1
              data-aos="fade-right"
              className="text-4xl font-bold sm:leading-10"
            >
              <span className="border-b-4 border-black">Wha</span>t We Offer
            </h1>
            <p className="py-6 text-md text-gray-500">
              We are a Blockchain company helping all kinds of enterprises
              implement the latest technologies by using innovative strategies
              to enhance their productivity and accelerate their growth so that
              they can make a name for themselves in the digital landscape. We
              offer a variety of services to cater to your business needs. From
              Blockchain services and solutions, Artificial Intelligence,
              Software Development, to Digital Marketing services, we’ve got you
              covered. Whether it’s a startup or an enterprise, we offer
              services that fit your company’s needs
            </p>
          </div>
          <div>
            <img src={ServiceImg} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceContent;
