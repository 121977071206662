import Navbar from '../Components/Navbar'
import ContactForm from '../Components/ContactForm'
import ContactCards from '../Components/ContactCards'
import Footer from '../Components/Footer'

const Contact = () => {
  return (
    <div>
      <Navbar/>
      <ContactForm/>
      <ContactCards/>
      <Footer/>
    </div>
  )
}

export default Contact
