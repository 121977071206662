import Accordians from "../Components/Accordians";
import ChooseSpaceX from "../Components/ChooseSpaceX";
import CustomCards from "../Components/CustomCards";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import ScrollUp from "../Components/ScrollUp";
import WebDevContent from "../Components/WebDevContent";
import WebDevHome from "../Components/WebDevHome";

const WebDev = () => {
  const accordionItems = [
    {
      id: "1",
      question: "What are web development services?",
      answer:
        "They are all activities used to develop websites for hosting on the internet. Web development services can range from developing static pages to full-fledged web applications.",
    },
    {
      id: "2",
      question: "What is included in website development?",
      answer:
        "It includes all development tasks from client-side/server-side scripting, network security configuration, content management system (CMS) development, and eCommerce development.",
    },
    {
      id: "3",
      question: "What is the cost of building a website?",
      answer:
        "Website development costs depend on the type of project. The costs primarily depend from company to company. Consult with our web development team and get an estimate of the exact rates depending on your requirements and specifications.",
    },
    {
      id: "4",
      question: "What are the main types of web development?",
      answer:
        "The main types of web development include front-end development, back-end development, and full-stack web development.",
    },
  ];

  const chooseSpaceXTitle = "Why Choose SpaceX?";
  const chooseSpaceXParagraphs = [
    "SpaceX is a website development agency in USA that has provided great services to its clients. By using advanced techniques and tools, we adhere to the best quality of work.",
    "By continuously improving our skill set, we develop expertise in the latest technologies that can help you build great websites. From the initial design to the delivery of the final product, our procedure is highly professional and transparent. Our goal is to provide the best customer experiences.",
    "Our team is proficient in providing the best custom web development services and they are currently working on JavaScript, PHP, NodeJs, ReactJs, Magento, WordPress, etc. We implement all the latest technologies so that you can strengthen your business and enhance your corporate branding. Let’s work together to build great websites.",
    "To develop the website according to your needs and requirements, you need highly advanced custom website development solutions that meet the needs of the modern market.",
    "SpaceX, with its highly experienced web development team, is one of the best technology experts in the USA that can design and develop custom web applications that meet your business needs. Our team of talented web developers takes our clients’ vision into strong consideration when developing websites that will appeal to their target audiences.",
  ];

  return (
    <div>
      <Navbar />
      <WebDevHome />
      <WebDevContent />
      <CustomCards />
      <Accordians items={accordionItems} />
      <ChooseSpaceX
        title={chooseSpaceXTitle}
        paragraphs={chooseSpaceXParagraphs}
      />
      <Footer/>
      <ScrollUp/>
    </div>
  );
};

export default WebDev;
