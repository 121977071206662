import "../Css/Main.css";
import fisrtCardImage from "../Assets/Images/Untitled.png";
import SecondCardImage from "../Assets/Images/Artifical_Intelligence.png";
import ThirdCardImage from "../Assets/Images/Staff.png";
import fourthCardImage from "../Assets/Images/Web-Develop.png";
import fifthCardImage from "../Assets/Images/Mobile_App.png";
import sixthCardImage from "../Assets/Images/ICO-marketing.png";
import seventhCardImage from "../Assets/Images/Digital_Marketing.png";
import eighthCardIamge from "../Assets/Images/UX_Design.png";

const BgServicesCards = () => {
  return (
    <div>
      <div className="bgImg-serviceSection">
        <div className="grid lg:grid-cols-3 gap-2 text-white">
          <div className="p-6 px-10 py-20 hover:bg-customRed shadow">
            <div className="flex justify-center">
              <img className="h-28" src={fisrtCardImage} alt="" />
            </div>
            <h5 className="py-3 text-[28px] font-bold tracking-tight text-white text-center">
              Metaverse
            </h5>
            <p className="mb-3 font-normal text-white text-center text-lg">
              Metaverse is a multidimensional digital space that lies beyond the
              world we live in. It’s a collection of all possible versions of a
              place or object, along with our perception of ownership and rights
              over them.
            </p>
            <a
              href="#"
              className="flex items-center px-3 py-2 text-sm font-medium text-center text-white justify-center"
            >
              Read more
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </div>
          <div className="p-6 px-10 py-20 hover:bg-customRed shadow">
            <div className="flex justify-center">
              <img className="h-28" src={SecondCardImage} alt="" />
            </div>
            <h5 className="py-3 text-[28px] font-bold tracking-tight text-white text-center">
              Artifical Intelligence
            </h5>
            <p className="mb-3 font-normal text-white text-center text-lg">
              Leverage the power of Artificial Intelligence to boost your
              business growth. Automate the entire process and achieve quicker
              results.
            </p>
            <a
              href="#"
              className="flex items-center px-3 py-2 text-sm font-medium text-center text-white justify-center"
            >
              Read more
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </div>
          <div className="p-6 px-10 py-20 hover:bg-customRed shadow">
            <div className="flex justify-center">
              <img className="h-28" src={ThirdCardImage} alt="" />
            </div>
            <h5 className="py-3 text-[28px] font-bold tracking-tight text-white text-center">
              Staff Augmentation
            </h5>
            <p className="mb-3 font-normal text-white text-center text-lg">
              Hire a qualified team to take care of specific tasks while you
              focus on the core of the business. Increase your productivity with
              a skilled team.
            </p>
            <a
              href="#"
              className="flex items-center px-3 py-2 text-sm font-medium text-center text-white justify-center"
            >
              Read more
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className="grid lg:grid-cols-3  gap-2 text-white">
          <div className="p-6 px-10 py-20 hover:bg-customRed shadow">
            <div className="flex justify-center">
              <img className="h-28" src={fourthCardImage} alt="" />
            </div>
            <h5 className="py-3 text-[28px] font-bold tracking-tight text-white text-center">
              Web Development
            </h5>
            <p className="mb-3 font-normal text-white text-center text-lg">
              We build websites that are fast and intuitive. Our web solutions
              enable you to enhance your online presence
            </p>
            <a
              href="#"
              className="flex items-center px-3 py-2 text-sm font-medium text-center text-white justify-center"
            >
              Read more
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </div>
          <div className="p-6 px-10 py-20 hover:bg-customRed shadow">
            <div className="flex justify-center">
              <img className="h-28" src={fifthCardImage} alt="" />
            </div>
            <h5 className="py-3 text-[28px] font-bold tracking-tight text-white text-center">
              Mobile App Development
            </h5>
            <p className="mb-3 font-normal text-white text-center text-lg">
              We create robust mobile applications that offer the best user
              experience, regardless of whichever device or platform you’re on.
            </p>
            <a
              href="#"
              className="flex items-center px-3 py-2 text-sm font-medium text-center text-white justify-center"
            >
              Read more
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </div>
          <div className="p-6 px-10 py-20 hover:bg-customRed shadow">
            <div className="flex justify-center">
              <img className="h-28" src={sixthCardImage} alt="" />
            </div>
            <h5 className="py-3 text-[28px] font-bold tracking-tight text-white text-center">
              ICO Marketing
            </h5>
            <p className="mb-3 font-normal text-white text-center text-lg">
              We offer an array of ICO Marketing services to provide sustainable
              results and to make it a huge success.
            </p>
            <a
              href="#"
              className="flex items-center px-3 py-2 text-sm font-medium text-center text-white justify-center"
            >
              Read more
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className="grid lg:grid-cols-2  gap-2 text-white">
          <div className="p-6 px-10 py-20 hover:bg-customRed shadow">
            <div className="flex justify-center">
              <img className="h-28" src={seventhCardImage} alt="" />
            </div>
            <h5 className="py-3 text-[28px] font-bold tracking-tight text-white text-center">
              Digital Marketing
            </h5>
            <p className="mb-3 font-normal text-white text-center text-lg">
              We offer Digital Marketing services that help your business with
              lead generation, making sales and increased ROI.
            </p>
            <a
              href="#"
              className="flex items-center px-3 py-2 text-sm font-medium text-center text-white justify-center"
            >
              Read more
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </div>
          <div className="p-6 px-10 py-20 hover:bg-customRed shadow">
            <div className="flex justify-center">
              <img className="h-28" src={eighthCardIamge} alt="" />
            </div>
            <h5 className="py-3 text-[28px] font-bold tracking-tight text-white text-center">
              UX Design
            </h5>
            <p className="mb-3 font-normal text-white text-center text-lg">
              We aim to deliver ultra-modern & user centric web & mobile
              application designs for your business needs.
            </p>
            <a
              href="#"
              className="flex items-center px-3 py-2 text-sm font-medium text-center text-white justify-center"
            >
              Read more
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BgServicesCards;
