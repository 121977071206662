import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./Routes/HomePage";
import About from "./Routes/About";
import Service from "./Routes/Service";
import Portfolio from "./Routes/Portfolio";
import Blog from "./Routes/Blog";
import Contact from "./Routes/Contact";
import Metaverse from "./Routes/Metaverse";
import Intellegence from "./Routes/Intellegence";
import WebDev from "./Routes/WebDev";
import AppDev from "./Routes/AppDev";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Service />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contactUs" element={<Contact />} />
          <Route path="/metaverse" element={<Metaverse />} />
          <Route path="/artificialIntellegence" element={<Intellegence />} />
          <Route path="/webdev" element={<WebDev />} />
          <Route path="/appdev" element={<AppDev />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
