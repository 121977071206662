import {
  FaGamepad,
  FaBookReader,
  FaLaptopHouse,
  FaRegBuilding,
  FaHandHoldingMedical,
  FaStore,
  FaUsers,
  FaHotel,
} from "react-icons/fa";
import { MdCalendarMonth } from "react-icons/md";

const MetaverseCards = () => {
  return (
    <div className="bg-customlighGray">
      <div className="max-w-[1140px] mx-auto p-4 pt-20">
        <div>
          <h1 className="text-3xl font-bold">
            Use of Metaverse in Various Industries
          </h1>
          <div className="border-b-4 border-black w-[70px] "></div>
          <p className="text-gray-500 text-lg py-7">
            Metaverse is a multidimensional digital space that lies beyond the
            world we live in. It’s a collection of all possible versions of a
            place or object, along with our perception of ownership and rights
            over them. It’s made up of layers that enable people to travel back
            and forth between real-world places and avatars; to transform into
            them; to explore more than one identity at a time, and choose how
            they evolve.
          </p>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-3 gap-4 py-10 max-sm:place-items-center">
          <div className="max-w-sm p-6  bg-white border shadow">
            <div className="flex justify-center py-5">
              <FaGamepad size={60} className="text-customRed" />
            </div>
            <h5 className="mb-2 text-[18px] font-bold tracking-tight text-black text-center">
              Gaming
            </h5>
            <p className="mb-3 font-normal text-gray-500 text-center text-lg">
              In-game NFT assets that are designated as having permanent
              ownership are compatible with the metaverse architecture.
            </p>
          </div>
          <div className="max-w-sm p-6  bg-white border shadow">
            <div className="flex justify-center py-5">
              <MdCalendarMonth size={60} className="text-customRed" />
            </div>
            <h5 className="mb-2 text-[18px] font-bold tracking-tight text-black text-center">
              Events
            </h5>
            <p className="mb-3 font-normal text-gray-500 text-center text-lg">
              Events no longer need to take place physically. By framing the
              actual dynamic events that anybody can participate in from
              anywhere in the world, virtual reality has assumed control of the
              new game.
            </p>
          </div>
          <div className="max-w-sm p-6  bg-white border shadow">
            <div className="flex justify-center py-5">
              <FaBookReader size={60} className="text-customRed" />
            </div>
            <h5 className="mb-2 text-[18px] font-bold tracking-tight text-black text-center">
              Education and Learning
            </h5>
            <p className="mb-3 font-normal text-gray-500 text-center text-lg">
              Metaverse education is a computer-based education system that
              integrates real life into the classroom. It uses the video game AR
              world to bring students together with the online community.
            </p>
          </div>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-3 gap-4 max-sm:place-items-center">
          <div className="max-w-sm p-6  bg-white border shadow">
            <div className="flex justify-center py-5">
              <FaLaptopHouse size={60} className="text-customRed" />
            </div>
            <h5 className="mb-2 text-[18px] font-bold tracking-tight text-black text-center">
              Software House
            </h5>
            <p className="mb-3 font-normal text-gray-500 text-center text-lg">
              To complement existing and upcoming systems and solutions, we
              create meta-based technologies and systems. We create the
              blockchain architecture and its components to improve technical
              support.
            </p>
          </div>
          <div className="max-w-sm p-6  bg-white border shadow">
            <div className="flex justify-center py-5">
              <FaRegBuilding size={60} className="text-customRed" />
            </div>
            <h5 className="mb-2 text-[18px] font-bold tracking-tight text-black text-center">
              Real estate
            </h5>
            <p className="mb-3 font-normal text-gray-500 text-center text-lg">
              Through our virtual real estate services, driven by the leader in
              the blockchain, cryptocurrency, and non-fungible token (NFT)
              industries, we assist in the acquisition of virtual property.
            </p>
          </div>
          <div className="max-w-sm p-6  bg-white border shadow">
            <div className="flex justify-center py-5">
              <FaHandHoldingMedical size={60} className="text-customRed" />
            </div>
            <h5 className="mb-2 text-[18px] font-bold tracking-tight text-black text-center">
              Healthcare
            </h5>
            <p className="mb-3 font-normal text-gray-500 text-center text-lg">
              With artificial intelligence, our healthcare services will benefit
              patients. Real-time avatars will become familiar with the
              patient's present physical and psychological condition and will
              indicate what has to be done in the event of therapy.
            </p>
          </div>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-3 gap-4 py-10 max-sm:place-items-center">
          <div className="max-w-sm p-6  bg-white border shadow">
            <div className="flex justify-center py-5">
              <FaStore size={60} className="text-customRed" />
            </div>
            <h5 className="mb-2 text-[18px] font-bold tracking-tight text-black text-center">
              Retail
            </h5>
            <p className="mb-3 font-normal text-gray-500 text-center text-lg">
              How we purchase retail goods will change as a result of the
              metaverse. Clothing companies like Adidas, Gucci, and Vans are
              getting ready to be present online. Retailers will be able to
              provide the greatest physical and digital experiences for their
              customers
            </p>
          </div>
          <div className="max-w-sm p-6  bg-white border shadow">
            <div className="flex justify-center py-5">
              <FaGamepad size={60} className="text-customRed" />
            </div>
            <h5 className="mb-2 text-[18px] font-bold tracking-tight text-black text-center">
              Social media and entertainment
            </h5>
            <p className="mb-3 font-normal text-gray-500 text-center text-lg">
              Celebrities will host virtual events, and the next generation's
              personal lives will be virtual as well, allowing us to host
              get-togethers with friends wherever we are, have intimate chats,
              and play games.
            </p>
          </div>
          <div className="max-w-sm p-6  bg-white border shadow">
            <div className="flex justify-center py-5">
              <FaHotel size={60} className="text-customRed" />
            </div>
            <h5 className="mb-2 text-[18px] font-bold tracking-tight text-black text-center">
              Hospitality
            </h5>
            <p className="mb-3 font-normal text-gray-500 text-center text-lg">
              We support the conventional hospitality business using disruptive
              technology and provide our visitors with an immersive experience
              to increase their level of involvement.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetaverseCards;
