
import BgServicesCards from '../Components/BgServicesCards'
import Footer from '../Components/Footer'
import HomeContent from '../Components/HomeContent'
import Navbar from '../Components/Navbar'
import ScrollUp from '../Components/ScrollUp'
import ServiceContent from '../Components/ServiceContent'

const Service = () => {
  return (
    <div>
      <Navbar/>
      <ServiceContent/>
      <BgServicesCards/>
      <HomeContent/>
      <Footer/>
      <ScrollUp/>
    </div>
  )
}

export default Service
