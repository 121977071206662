import Accordians from "../Components/Accordians";
import ChooseSpaceX from "../Components/ChooseSpaceX";
import Footer from "../Components/Footer";
import IntellegenceContent from "../Components/IntellegenceContent";
import IntellegnceHome from "../Components/IntellegnceHome";
import Navbar from "../Components/Navbar";
import ScrollUp from "../Components/ScrollUp";

const Intellegence = () => {
  const AccordionItem = [
    {
      id: "1",
      question: "What services do AI companies provide?",
      answer:
        "AI companies provide a wide range of services, including Customer Analytics, NLP, ML Operations, RPA, and Anomaly Detection.",
    },
    {
      id: "2",
      question: "How much does it cost to hire an AI Engineer?",
      answer:
        "The cost of hiring an AI developer varies from company to company. It also depends on their skill set and the level of expertise they own",
    },
    {
      id: "3",
      question: "How is AI used in software development?",
      answer:
        "AI enables computers to do fast and accurate testing that helps reduce errors and shortens the development cycle. Software engineers make use of AI to streamline processes and hand over manual and repetitive tasks that can do it faster and more effectively.",
    },
  ];

  const chooseSpaceXTitle = "Why Choose SpaceX?";
  const chooseSpaceXParagraphs = [
    "As an AI development company, we excel at delivering custom Artificial Intelligence solutions that help our clients achieve their business objectives and achieve sustainable growth. AI technology enhances your sales processes as it keeps tabs on your email, calendar, and phone schedule. We take care of your sales pipeline with our AI-based applications so that they help you focus on making the best deals. Our AI solutions enhance the capability of making successful sales and converting leads into potential customers.",
    "Our AI solutions for customer services store the responses of your clients and keep track of unsatisfied customers to provide you with valuable feedback. We bring AI technology together so that you can make smarter and faster decisions that help your organization achieve scalable growth. Our AI development services will lead to fewer chances of human errors as the entire business process is now automated. They also help speed up business processes as monotonous and tedious tasks can be completed at a faster pace without the need for manual intervention.",
    "They also lead to enhanced productivity as monotonous tasks are reduced, and the focus is geared towards high-priority tasks.",
  ];

  return (
    <div>
      <Navbar />
      <IntellegnceHome />
      <IntellegenceContent />
      <Accordians items={AccordionItem} />
      <ChooseSpaceX
        title={chooseSpaceXTitle}
        paragraphs={chooseSpaceXParagraphs}
      />
      <Footer />
      <ScrollUp/>
    </div>
  );
};

export default Intellegence;
