import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

interface AccordionItem {
  id: string;
  question: string;
  answer: string;
}

interface AccordiansProps {
  items: AccordionItem[];
}

const Accordians: React.FC<AccordiansProps> = ({ items }) => {
  const [activeAccordion, setActiveAccordion] = useState<string | null>(null);

  const toggleAccordion = (accordionId: string) => {
    setActiveAccordion((prevAccordion) =>
      prevAccordion === accordionId ? null : accordionId
    );
  };

  const isAccordionActive = (accordionId: string) => {
    return activeAccordion === accordionId;
  };
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      offset: 200,
    });
  }, []);

  return (
    <div>
      <div className="max-w-[1140px] mx-auto mt-10 pt-7 p-4">
        <div data-aos="fade-right">
          <h1 className="text-3xl font-bold">FAQ'S</h1>
          <div className="border-b-4 border-black w-[70px] "></div>
        </div>
        <div
          data-aos="fade-up"
          id="accordion-flush"
          data-accordion="collapse"
          data-active-classes="bg-white dark:bg-gray-900 text-gray-900 dark:text-white"
          data-inactive-classes="text-gray-500 dark:text-gray-400"
          className="my-10"
        >
          {items.map((item) => (
            <div key={item.id}>
              <h2 id={`accordion-flush-heading-${item.id}`}>
                <button
                  type="button"
                  className="flex items-center justify-between w-full py-5 font-bold text-xl bg-gray-300 p-4 text-black border-b border-gray-200 gap-3"
                  onClick={() =>
                    toggleAccordion(`accordion-flush-body-${item.id}`)
                  }
                  aria-expanded={isAccordionActive(
                    `accordion-flush-body-${item.id}`
                  )}
                  aria-controls={`accordion-flush-body-${item.id}`}
                >
                  <span>{item.question}</span>
                  <svg
                    className={`w-3 h-3 ${
                      isAccordionActive(`accordion-flush-body-${item.id}`)
                        ? "rotate-0"
                        : "rotate-180"
                    } shrink-0`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5 5 1 1 5"
                    />
                  </svg>
                </button>
              </h2>
              <div
                id={`accordion-flush-body-${item.id}`}
                className={`py-1 transition-opacity duration-300 ease-in-out ${
                  isAccordionActive(`accordion-flush-body-${item.id}`)
                    ? "opacity-100"
                    : "opacity-0 max-h-0 overflow-hidden"
                }`}
                aria-labelledby={`accordion-flush-heading-${item.id}`}
                style={{
                  maxHeight: isAccordionActive(
                    `accordion-flush-body-${item.id}`
                  )
                    ? "none"
                    : "0px",
                }}
              >
                <div className="border-b border-gray-200 bg-gray-100 p-4">
                  <p className="mb-2 text-gray-500 dark:text-gray-400">
                    {item.answer}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* CONTACT US BUTTON */}
      <div className="overlay-bg py-8 my-5">
        <Link to="/contactUs">
          <div data-aos="fade-up" className="my-10 flex justify-center">
            <button className="text-white bg-customRed cursor-pointer focus:outline-none font-bold rounded-md text-xl  px-9 py-3 text-center hover:scale-110 transition ease-in-out">
              Contact Us
            </button>
          </div>
        </Link>
      </div>
      {/* BUTTON END */}
    </div>
  );
};

export default Accordians;
