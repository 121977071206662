import WebdevelopmentImg from "../Assets/Images/Web-Development.svg";
import { FaReact,FaNodeJs,FaShopify,FaWordpressSimple } from "react-icons/fa";
import { SiDjango } from "react-icons/si";
import { LiaLaravel } from "react-icons/lia";

const Webdevelopment = () => {
  return (
    <>
      <div className="max-w-[1140px] mx-auto my-10 p-4">
        <div className="grid lg:grid-cols-2 py-10 items-center">
          <div>
            <h1 data-aos="fade-right" className="text-4xl font-bold lg:pt-16">
              <span className="border-b-4 border-black">Web</span> Apps
              Development
            </h1>
            <p className="py-7 text-gray-400">
              We use a variety of platforms to develop stunning web applications
              that convert.
            </p>
            <div className="grid lg:grid-cols-3 md:grid-cols-2  gap-5">
              <div className="flex items-center justify-center flex-col">
                <FaReact size={70} className="text-customRed" />
                <p className="text-gray-400 py-4">React</p>
              </div>
              <div className="flex items-center justify-center flex-col">
                <FaNodeJs size={70} className="text-customRed"/>
                <p className="text-gray-400 py-4">Node.Js</p>
              </div>
              <div className="flex items-center justify-center flex-col">
                <FaWordpressSimple size={70} className="text-customRed" />
                <p className="text-gray-400 py-4">WordPress</p>
              </div>
              <div className="flex items-center justify-center flex-col">
                <SiDjango size={70} className="text-customRed" />
                <p className="text-gray-400 py-4">Django</p>
              </div>
              <div className="flex items-center justify-center flex-col">
                <FaShopify size={70} className="text-customRed" />
                <p className="text-gray-400 py-4">Shopify</p>
              </div>
              <div className="flex items-center justify-center flex-col">
                <LiaLaravel size={70} className="text-customRed" />
                <p className="text-gray-400 py-4">Laravel</p>
              </div>
            </div>
          </div>
          <div data-aos="fade-left" className="flex justify-end">
            <img src={WebdevelopmentImg} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Webdevelopment;
