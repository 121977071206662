import "../Css/Main.css";
import deepfakesImg from "../Assets/Images/Deep-fakes.jpg";
import BlockChainIoTImg from "../Assets/Images/blockchain-iot.webp";
import SeoEngineImg from "../Assets/Images/SEO WEB.jpeg";
import HealthCareImg from "../Assets/Images/HeathCare.jpeg";
import StaffIcoImg from "../Assets/Images/StaffICO.jpg";
import NFTImg from "../Assets/Images/NFTGame.webp";
import SmartContractImg from "../Assets/Images/SmartContract.png";
import WebFutureImg from "../Assets/Images/WB3future.png";
import DecentralizedImg from "../Assets/Images/Decentralized.jpg";

const BlogContent = () => {
  return (
    <div className="max-w-[1140px] mx-auto mt-36 mb-20 p-4 shadow-lg rounded-md">
      <div className="grid lg:grid-cols-2 gap-8 cursor-pointer">
        <div>
          <img
            className="rounded-xl transition-all hover:scale-105 duration-300 ease-in-out h-[295px] w-[100%]"
            src={deepfakesImg}
            alt=""
          />
        </div>
        <div>
          <h1 className="lg:text-3xl md:text-2xl max-sm:text-xl font-extrabold">
            How Blockchain Fights Back Against AI Misinformation and Deepfakes
          </h1>
          <p className="py-5 text-gray-500 text-lg">
            In a world where it’s hard to tell real from fake, the rise of
            AI-driven misinformation and deepfakes is like a digital maze we’re
            navigating blindly. But, wait – there’s a ray of hope! The hero in
            this story is blockchain technology, here to tackle these misleading
            monsters head-on. In this journey, we’ll explore how .
          </p>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 gap-8 cursor-pointer lg:pt-28 md:pt-10 max-sm:pt-8">
        <div>
          <img
            className="rounded-xl transition-all hover:scale-105 duration-300 ease-in-out h-[295px] w-[100%]"
            src={BlockChainIoTImg}
            alt=""
          />
        </div>
        <div>
          <h1 className="lg:text-3xl md:text-2xl max-sm:text-xl font-extrabold">
            Securing IoT with Blockchain and AI: A Comprehensive Guide
          </h1>
          <p className="py-5 text-gray-500 text-lg">
            In today’s interconnected world, the Internet of Things (IoT) has
            revolutionized how devices and systems communicate and collaborate.
            From smart homes to industrial automation, IoT has ushered in an era
            of convenience and efficiency. However, this rapid proliferation of
            interconnected devices has also raised significant security
            concerns.
          </p>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 gap-8 cursor-pointer lg:pt-28 md:pt-10 max-sm:pt-8">
        <div>
          <img
            className="rounded-xl transition-all hover:scale-105 duration-300 ease-in-out h-[295px] w-[100%]"
            src={SeoEngineImg}
            alt=""
          />
        </div>
        <div>
          <h1 className="lg:text-3xl md:text-2xl max-sm:text-xlfont-extrabold">
            Web3 Search Engines: A New Frontier for SEO and Digital Visibility
          </h1>
          <p className="py-5 text-gray-500 text-lg">
            As the digital landscape continues its relentless evolution, we are
            witnessing a paradigm shift in understanding and interacting with
            the Internet. We’ve moved from static pages in Web1 to the
            interactive and social Web2, and now, we stand on the precipice of a
            new digital frontier: Web3. With the emergence of Web3 search
            engines, businesses.
          </p>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 gap-8 cursor-pointer lg:pt-28 md:pt-10 max-sm:pt-8 ">
        <div>
          <img
            className="rounded-xl transition-all hover:scale-105 duration-300 ease-in-out h-[295px] w-[100%]"
            src={HealthCareImg}
            alt=""
          />
        </div>
        <div>
          <h1 className="lg:text-3xl md:text-2xl max-sm:text-xl font-extrabold">
            Hyperledger Healthcare: Transforming the Healthcare Industry
          </h1>
          <p className="py-5 text-gray-500 text-lg">
            The healthcare industry, a vital pillar of society, is experiencing
            a revolutionary transformation fueled by cutting-edge technologies.
            One such groundbreaking force is Hyperledger Healthcare. This
            innovative framework, built on the principles of blockchain
            technology, is poised to revolutionize healthcare operations, data
            security, patient care, and much more. In this blog post, we’ll
            delve into the .
          </p>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 gap-8 cursor-pointer  lg:pt-28 md:pt-10 max-sm:pt-8 ">
        <div>
          <img
            className="rounded-xl transition-all hover:scale-105 duration-300 ease-in-out h-[295px] w-[100%]"
            src={StaffIcoImg}
            alt=""
          />
        </div>
        <div>
          <h1 className="lg:text-3xl md:text-2xl max-sm:text-xl font-extrabold">
            Web3 Staff Augmentation for Token Sale and ICO Projects
          </h1>
          <p className="py-5 text-gray-500 text-lg">
            In the rapidly evolving world of blockchain and cryptocurrency,
            token sales and Initial Coin Offerings (ICOs) have become powerful
            fundraising methods for startups and established companies alike.
            The success of these projects heavily relies on a competent and
            knowledgeable team. However, finding and assembling the right team
            can be a challenging task. This is where .
          </p>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 gap-8 cursor-pointer  lg:pt-28 md:pt-10 max-sm:pt-8 ">
        <div>
          <img
            className="rounded-xl transition-all hover:scale-105 duration-300 ease-in-out h-[295px] w-[100%]"
            src={NFTImg}
            alt=""
          />
        </div>
        <div>
          <h1 className="lg:text-3xl md:text-2xl max-sm:text-xl font-extrabold">
            NFT Game Art vs Game Design: Future of Interactive Experiences
          </h1>
          <p className="py-5 text-gray-500 text-lg">
            In the ever-evolving landscape of the gaming industry, two
            compelling forces have emerged as transformative agents: NFT game
            art and game design. These dynamic elements are shaping the future
            of gaming, pushing boundaries and redefining how players interact
            with virtual worlds. In this article, we embark on an enlightening
            journey to explore the profound differences .
          </p>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 gap-8 cursor-pointer  lg:pt-28 md:pt-10 max-sm:pt-8 ">
        <div>
          <img
            className="rounded-xl transition-all hover:scale-105 duration-300 ease-in-out h-[295px] w-[100%]"
            src={SmartContractImg}
            alt=""
          />
        </div>
        <div>
          <h1 className="lg:text-3xl md:text-2xl max-sm:text-xl font-extrabold">
            Unraveling the Role of Smart Contracts in Blockchain White Papers
          </h1>
          <p className="py-5 text-gray-500 text-lg">
            In the ever-evolving landscape of blockchain technology, smart
            contracts have emerged as a disruptive force, revolutionizing the
            way agreements are made and executed. This blog post delves deep
            into the transformative role of smart contracts in the realm of
            blockchain white papers. As we embark on this journey, we will
            unravel the intricacies of smart .
          </p>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 gap-8 cursor-pointer  lg:pt-28 md:pt-10 max-sm:pt-8 ">
        <div>
          <img
            className="rounded-xl transition-all hover:scale-105 duration-300 ease-in-out h-[295px] w-[100%]"
            src={WebFutureImg}
            alt=""
          />
        </div>
        <div>
          <h1 className="lg:text-3xl md:text-2xl max-sm:text-xl font-extrabold">
            Web3 and the Future of Digital Advertising Analytics
          </h1>
          <p className="py-5 text-gray-500 text-lg">
            Welcome to the future of digital advertising analytics! In this blog
            post, we explore the transformative potential of Web3 technologies
            in shaping the way we measure, analyze, and optimize advertising
            campaigns. As the digital advertising landscape continues to evolve,
            Web3 presents innovative solutions that enhance transparency,
            privacy, and data ownership. Join us as we uncover .
          </p>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 gap-8 cursor-pointer  lg:pt-28 md:pt-10 max-sm:pt-8 ">
        <div>
          <img
            className="rounded-xl transition-all hover:scale-105 duration-300 ease-in-out h-[295px] w-[100%]"
            src={DecentralizedImg}
            alt=""
          />
        </div>
        <div>
          <h1 className="lg:text-3xl md:text-2xl max-sm:text-xl font-extrabold">
            The Future Unleashed: An Introduction to Decentralized Marketplaces
          </h1>
          <p className="py-5 text-gray-500 text-lg">
            Welcome to the future of commerce! In this blog post, we embark on a
            journey into the world of decentralized marketplaces. These
            innovative platforms are revolutionizing traditional e-commerce by
            leveraging the power of blockchain technology. Decentralized
            marketplace empower individuals to transact directly with each
            other, eliminating intermediaries and fostering trust, transparency,
            and efficiency. Get ready .
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogContent;
