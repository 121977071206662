import InvoImg from "../Assets/Images/Invo.png";
import OwnerClubImg from "../Assets/Images/OnwerClub.png";
import LiquidityImg from "../Assets/Images/LiquidityDigital.png";
import RewardsImg from "../Assets/Images/Reward.png";
import DataRoversImg from "../Assets/Images/DataRovers.png";
import WurqiImg from "../Assets/Images/Wurqi.png";
import CashiiImg from "../Assets/Images/Cashii.png";
import NftMarketplace from "../Assets/Images/NFTportfolio.png";
import DinisiumImg from "../Assets/Images/Dinisum.png";
import BLOCommerenceImg from "../Assets/Images/BLDCommerence.png";
import VigoueMeImg from "../Assets/Images/VigourMe.png";
import QuPortImg from "../Assets/Images/QuPort.png";
import OrangeSwapImg from "../Assets/Images/OrangeSwap.png";
import PrizeBondsImg from "../Assets/Images/PrizeBonds.png";

const PortFolioContent = () => {
  return (
    <div className="max-w-[1140px] mx-auto mt-36 mb-20 p-4">
      <div className="grid lg:grid-cols-2 gap-8 place-items-center">
        <div>
          <img className="h-[275px] w-[100%]" src={InvoImg} alt="" />
        </div>
        <div>
          <h1 className="lg:text-3xl md:text-2xl max-sm:text-xl font-extrabold">
            SpaceX + Our Invo
          </h1>
          <div className="border-b-4 pt-2 border-black w-[60px] "></div>
          <p className="py-5 text-gray-500 text-lg">
            Our Invo provides an enhancing gaming experience for players and
            also helps in providing different revenue streams for game
            developers. The main aim of Our Invo is to work on how value is
            transferred across different games. The platform acts as a bridge
            between players and different games – the technology used allows
            players to carry their assets from one gaming universe to another.
          </p>
          <p className=" text-gray-500 text-lg">
            Furthermore, the groundbreaking solutions used by Our Invo also open
            various opportunities for game developers to increase their revenue
            and player revenue. Our Invo plans on creating a platform for both
            players and developers so they can thrive and continue their passion
            for gaming.
          </p>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 gap-8 lg:pt-28 md:pt-10 max-sm:pt-14 place-items-center">
        <div>
          <h1 className="lg:text-3xl md:text-2xl max-sm:text-xl font-extrabold">
            SpaceX+ Owner’s Club
          </h1>
          <div className="border-b-4 pt-2 border-black w-[60px] "></div>
          <p className="py-5 text-gray-500 text-lg">
            Owner’s Club leverages their own “Winner’s Circle,” a patented live
            virtual racing system with AI-powered horses and realistic racing
            conditions, to reinvent the interactive skill gaming experience.
            Owner’s Club simulates the thrill of live racing events by combining
            elements of skill gaming, block chain technology, and horse racing
            within the metaverse of Invincible GG. Users can digitally own
            3D-rendered horses and compete in exciting races on historic tracks.
          </p>
          <p className="py-5 text-gray-500 text-lg">
            Owner’s Club is launching a revolutionary platform that will
            transform the gaming industry by making the game available to the
            general public. With Owner’s Club, users may enter the gaming future
            and enjoy an unparalleled gaming experience through virtual sports,
            AI-powered NFTs, and their own abilities.
          </p>
        </div>
        <div>
          <img className="h-[275px] w-[100%]" src={OwnerClubImg} alt="" />
        </div>
      </div>
      <div className="grid lg:grid-cols-2 gap-8 lg:pt-28 md:pt-10 max-sm:pt-14 place-items-center">
        <div>
          <img className="h-[275px] w-[100%]" src={LiquidityImg} alt="" />
        </div>
        <div>
          <h1 className="lg:text-3xl md:text-2xl max-sm:text-xl font-extrabold">
            SpaceX+ Liquidity Digital
          </h1>
          <div className="border-b-4 pt-2 border-black w-[60px] "></div>
          <p className="py-5 text-gray-500 text-lg">
            Liquidity Digital is a Fintech company that is currently working
            towards creating an innovative platform that will help increase the
            capital formation by acting as a bridge between institutional grade
            security issuances with global investors. The main focus for
            Liquidity Digital are the private capital markets.
          </p>
          <p className=" text-gray-500 text-lg">
            Liquidity Digital has deduced that a lot of global capital is locked
            up behind illiquid assets. Hence, institutions and individuals both
            are unable to take advantage of these assets. Thus, Liquidity
            Digital plans on establishing an end-to-end platform that helps with
            issuance and complete life cycle management of programmable digital
            securities and their underlying smart contracts.
          </p>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 gap-8 lg:pt-28 md:pt-10 max-sm:pt-14 place-items-center">
        <div>
          <h1 className="lg:text-3xl md:text-2xl max-sm:text-xl font-extrabold">
            SpaceX+ Rewards4U
          </h1>
          <div className="border-b-4 pt-2 border-black w-[60px] "></div>
          <p className="py-5 text-gray-500 text-lg">
            Rewards4U plans to leverage blockchain technology to transform the
            loyalty rewards market and build a smooth, open, and user-focused
            rewards ecosystem.
          </p>
          <p className="py-5 text-gray-500 text-lg">
            Rewards4U sees a time when the constraints of conventional programs
            will no longer apply to loyalty awards. The platform wants to build
            an interoperable rewards platform that lets consumers take advantage
            of all the benefits they have, no matter which loyalty program they
            came from, by utilizing the power of block chain. Their goal is to
            lead the way in innovation by providing a fresh set of loyalty
            solutions that are beneficial to both companies and consumers.
          </p>
        </div>
        <div>
          <img className="h-[275px] w-[100%]" src={RewardsImg} alt="" />
        </div>
      </div>
      <div className="grid lg:grid-cols-2 gap-8 lg:pt-28 md:pt-10 max-sm:pt-14 place-items-center">
        <div>
          <img className="h-[275px] w-[100%]" src={DataRoversImg} alt="" />
        </div>
        <div>
          <h1 className="lg:text-3xl md:text-2xl max-sm:text-xl font-extrabold">
            SpaceX+ DataRoverss
          </h1>
          <div className="border-b-4 pt-2 border-black w-[60px] "></div>
          <p className="py-5 text-gray-500 text-lg">
            DataRovers is built to resolve the issue of hospital losses from
            insurance companies or payers. This system allows the hospital to
            make decisions about insurance-related patients’ claims and generate
            insights into the current and future revenue generated from
            insurance companies.
          </p>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 gap-8 lg:pt-28 md:pt-10 max-sm:pt-14 place-items-center">
        <div>
          <h1 className="lg:text-3xl md:text-2xl max-sm:text-xl font-extrabold">
            SpaceX+ Wurqi for School
          </h1>
          <div className="border-b-4 pt-2 border-black w-[60px] "></div>
          <p className="py-5 text-gray-500 text-lg">
            The educational industry has experienced many radical changes in the
            last two decades. The industry is more competitive than ever before.
            Thus, to keep the competitive advantage the educational sector must
            adopt the technological and other changes happening worldwide. To
            overcome the situation and to meet the requirements of the latest
            technology WFS presents a School Management System, the best
            software solution for the education industry.
          </p>
        </div>
        <div>
          <img className="h-[275px] w-[100%]" src={WurqiImg} alt="" />
        </div>
      </div>
      <div className="grid lg:grid-cols-2 gap-8 lg:pt-28 md:pt-10 max-sm:pt-14 place-items-center">
        <div>
          <img className="h-[275px] w-[100%]" src={CashiiImg} alt="" />
        </div>
        <div>
          <h1 className="lg:text-3xl md:text-2xl max-sm:text-xl font-extrabold">
            SpaceX+ Cashii
          </h1>
          <div className="border-b-4 pt-2 border-black w-[60px] "></div>
          <p className="py-5 text-gray-500 text-lg">
            Cashii is a cryptocurrency wallet with a decentralized chatting
            strategy which provides users with a seamless transactional
            experience. Cashii uses multichain technology and is regulated by
            Fintrac.
          </p>
          <p className="text-gray-500 text-lg">
            Cashii provides users to protect their wealth and have complete
            freedom over their crypto currency with a Self-Custodial wallet.
            Furthermore, it allows users to socialize with other wallet owners
            through secure end-to-end encrypted messages. Furthermore, the app
            is user friendly and hassle free to use providing users with a
            seamless experience.
          </p>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 gap-8 lg:pt-28 md:pt-10 max-sm:pt-14 place-items-center">
        <div>
          <h1 className="lg:text-3xl md:text-2xl max-sm:text-xl font-extrabold">
            SpaceX+ Fox Music NFT Marketplace
          </h1>
          <div className="border-b-4 pt-2 border-black w-[60px] "></div>
          <p className="py-5 text-gray-500 text-lg">
            Music NFT is a trending way that offers its creator ownership using
            block chain technology. Fox Music NFT Marketplace allows musicians
            totheir NFTs to represent their musical work. By doing so, they’ll
            be verified owners of their NFTs.
          </p>
          <p className="text-gray-500 text-lg">
            It helps musicians in getting royalties. Moreover, there is a
            community of listeners present at Fox Music NFT Marketplace allowing
            musicians to showcase their music in a unique way to a wide audience
          </p>
        </div>
        <div>
          <img className="h-[275px] w-[100%]" src={NftMarketplace} alt="" />
        </div>
      </div>
      <div className="grid lg:grid-cols-2 gap-8 lg:pt-28 md:pt-10 max-sm:pt-14 place-items-center">
        <div>
          <img className="h-[275px] w-[100%]" src={DinisiumImg} alt="" />
        </div>
        <div>
          <h1 className="lg:text-3xl md:text-2xl max-sm:text-xl font-extrabold">
            SpaceX+ Cashii
          </h1>
          <div className="border-b-4 pt-2 border-black w-[60px] "></div>
          <p className="py-5 text-gray-500 text-lg">
            Cashii is a cryptocurrency wallet with a decentralized chatting
            strategy which provides users with a seamless transactional
            experience. Cashii uses multichain technology and is regulated by
            Fintrac.
          </p>
          <p className="text-gray-500 text-lg">
            Cashii provides users to protect their wealth and have complete
            freedom over their crypto currency with a Self-Custodial wallet.
            Furthermore, it allows users to socialize with other wallet owners
            through secure end-to-end encrypted messages. Furthermore, the app
            is user friendly and hassle free to use providing users with a
            seamless experience.
          </p>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 gap-8 lg:pt-28 md:pt-10 max-sm:pt-14 place-items-center">
        <div>
          <h1 className="lg:text-3xl md:text-2xl max-sm:text-xl font-extrabold">
            SpaceX+ Blo Commerce
          </h1>
          <div className="border-b-4 pt-2 border-black w-[60px] "></div>
          <p className="py-5 text-gray-500 text-lg">
            Blo Commerce is a platform that provides all under one roof for its
            users. Using Blo Commerce, users can aggregate their wallets and NFT
            collections on a safe platform. Furthermore, it allows users to
            create new NFT assets and manage them in a systematic and ethical
            way. Blo Commerce also allows users to track all their NFT
            activities – this allows users to increase their profits greatly.
            Moreover, it simplifies tax reporting for users, making it a hassle
            free process.
          </p>
        </div>
        <div>
          <img className="h-[275px] w-[100%]" src={BLOCommerenceImg} alt="" />
        </div>
      </div>
      <div className="grid lg:grid-cols-2 gap-8 lg:pt-28 md:pt-10 max-sm:pt-14 place-items-center">
        <div>
          <img className="h-[275px] w-[100%]" src={VigoueMeImg} alt="" />
        </div>
        <div>
          <h1 className="lg:text-3xl md:text-2xl max-sm:text-xl font-extrabold">
            SpaceX+ VigourMe
          </h1>
          <div className="border-b-4 pt-2 border-black w-[60px] "></div>
          <p className="py-5 text-gray-500 text-lg">
            VignourMe is an online intimate health consultation app. The app
            helps connect users with experienced and qualified healthcare
            professionals that can help users understand, diagnose, and treat
            any intimate dysfunction or related issues. The app can be accessed
            from any mobile device or computer. Moreover, all the data received
            by the app is strictly confidential!
          </p>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 gap-8 lg:pt-28 md:pt-10 max-sm:pt-14 place-items-center">
        <div>
          <h1 className="lg:text-3xl md:text-2xl max-sm:text-xl font-extrabold">
            SpaceX+ QuPort
          </h1>
          <div className="border-b-4 pt-2 border-black w-[60px] "></div>
          <p className="py-5 text-gray-500 text-lg">
            QuPort is known for its focus on AI, data analytics, and pioneering
            technologies like Web 3.0 and Blockchain. QuPort helps startups to
            enter the global market, by offering them with unparalleled
            mentoring and coaching, helping them in creating strategic business
            consultancy, and navigating through different technologies and using
            them for their own startups
          </p>
        </div>
        <div>
          <img className="h-[275px] w-[100%]" src={QuPortImg} alt="" />
        </div>
      </div>
      <div className="grid lg:grid-cols-2 gap-8 lg:pt-28 md:pt-10 max-sm:pt-14 place-items-center">
        <div>
          <img className="h-[275px] w-[100%]" src={OrangeSwapImg} alt="" />
        </div>
        <div>
          <h1 className="lg:text-3xl md:text-2xl max-sm:text-xl font-extrabold">
            SpaceX+ Orange Swap
          </h1>
          <div className="border-b-4 pt-2 border-black w-[60px] "></div>
          <p className="py-5 text-gray-500 text-lg">
            Orange Swap is a decentralized exchange that enables the trading of
            digital assets. FOX is the cryptocurrency the Orange Swap platform
            uses. Anyone can earn FOX by agreeing to not sell or trade their
            crypto holdings. The Orange Swap platform is governed by FOX holders
            in proportion to how much FOX they own.
          </p>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 gap-8 lg:pt-28 md:pt-10 max-sm:pt-14 place-items-center">
        <div>
          <h1 className="lg:text-3xl md:text-2xl max-sm:text-xl font-extrabold">
            SpaceX+ Prize Bonds
          </h1>
          <div className="border-b-4 pt-2 border-black w-[60px] "></div>
          <p className="py-5 text-gray-500 text-lg">
            Prize Bonds aims to facilitate the broad use of Bonds in the Crypto
            Market by allowing Prize Bonds holders to diversify their portfolio
            and create a fun way to Invest, Save and Win through a raffle
            system. Prize Bonds go one step further by implementing these bonds
            into the Crypto Industry with the advantages of blockchain
            technology. Unlike other crypto initiatives, there is no need to
            purchase any tickets to participate in the draws. Only by owning or
            buying Prize Bonds will you have the chance to win monthly prizes
            paid in cryptocurrency.
          </p>
        </div>
        <div>
          <img className="h-[275px] w-[100%]" src={PrizeBondsImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default PortFolioContent;
