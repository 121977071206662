import React, { useEffect } from "react";
import HomeImage from "../Assets/Images/HomeImg.jpg";
import illustration from "../Assets/Images/Illustration.svg";
import "../Css/Main.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Particle from "../Components/Particles";


const HomeImg = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      offset: 200,
    });
  }, []);
  return (
    <>
      <Particle />
      <div
        className="relative grid lg:grid-cols-2 py-20 lg:mt-28 md:mt-28"
        style={{
          backgroundImage: `url(${HomeImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* First grid cell for text */}
        <div className="flex flex-col px-10 justify-center bg-black bg-opacity-50 text-margin">
          <h1 className="text-white lg:text-2xl pb-3">
            Optimize Your Path with our Blockchain Services
          </h1>
          <h1
            data-aos="fade-right"
            className="text-white lg:text-5xl sm:text-4xl font-bold pt-1"
          >
            Spark Your Evolution with Our Comprehensive Blockchain Offerings
          </h1>
          <h1 className="text-white text-xl pt-5">
            As an accomplished blockchain development company, SpaceX is
            steadfast in providing our clients with top-notch WEB3 solution of
            the highest quality.
          </h1>
          {/* <div>
            <button className="bg-customRed text-white mt-4 px-6 py-2 rounded-md font-bold">
              Book Free Consultation
            </button>
          </div> */}
        </div>
        {/* Second grid cell for image */}
        <div
          data-aos="fade-left"
          className="flex flex-col px-10 justify-center md-mt-10"
        >
          <img className="h-96" src={illustration} alt="" />
        </div>
      </div>
    </>
  );
};

export default HomeImg;
