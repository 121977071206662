import Accordians from "../Components/Accordians";
import Footer from "../Components/Footer";
import MetaverseCards from "../Components/MetaverseCards";
import MetaverseContent from "../Components/MetaverseContent";
import MetaverseHome from "../Components/MetaverseHome";
import Navbar from "../Components/Navbar";
import ScrollUp from "../Components/ScrollUp";

const Metaverse = () => {
  const accordionItems = [
    {
      id: "1",
      question: "How Is Metaverse Beneficial for Any Business Growth?",
      answer:
        "The Metaverse platform is built to get companies up and running as quickly and efficiently as possible. It also offers a host of tools that let you know precisely what needs to be done, what will work, and what has already been done effectively.",
    },
    {
      id: "2",
      question: "What is the Main Challenge Associated with the Metaverse?",
      answer:
        "The main challenge associated with the metaverse is trying to create a virtual world that can be used in everyday life. People need to be able to distinguish between real and emergent entities. Also, users need a means of tagging their knowledge to keep track of it and enable others to use it at will.",
    },
    {
      id: "3",
      question: "How Metaverse Will Change the World?",
      answer:
        "Metaverse aims to change our world by replacing the current online user experience with more valuable alternatives. We envision a world of higher consciousness, where people work together on multiple projects and build organizations through their contributions in virtual reality worlds across the metaverse.",
    },
  ];

  return (
    <div>
      <Navbar />
      <MetaverseHome />
      <MetaverseCards />
      <MetaverseContent />
      <Accordians items={accordionItems} />
      <Footer />
      <ScrollUp/>
    </div>
  );
};

export default Metaverse;
