import { useState } from "react";
import LogoImg from "../Assets/Images/SpaceX Logo.png";
import { Link } from "react-router-dom";
import "../Css/navbar.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <nav className="bg-white fixed w-full top-0 start-0 border-b border-gray-200 z-2000">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <Link
            to="/"
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <img src={LogoImg} className="h-20" alt="" />
          </Link>
          <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
            <Link to="/contactUs">
              <button
                type="button"
                className="text-white bg-customRed cursor-pointer focus:outline-none font-bold rounded-md text-sm px-7 py-3 hover:scale-110 transition ease-in-out text-center max-sm:hidden"
              >
                Get a Quote
              </button>
            </Link>
            <button
              onClick={toggleNavbar}
              type="button"
              className="text-white bg-customBlue hover:bg-customBlue focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-4 py-2 text-center md:hidden"
            >
              {isOpen ? (
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 17 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 17 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 1h15M1 7h15M1 13h15"
                  />
                </svg>
              )}
            </button>
          </div>
          <div
            className={`${
              isOpen ? "block" : "hidden"
            } items-center justify-between w-full md:flex md:w-auto md:order-1`}
            id="navbar-sticky"
          >
            <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white">
              <li>
                <Link
                  to="/about"
                  className="block py-2 px-3 text-lg bg-customRed text-customBlue rounded md:bg-transparent md:hover:text-customRed md:p-0 "
                  aria-current="page"
                >
                  About Us
                </Link>
              </li>
              <div className="dropdown">
                <Link to="/services">
                  <button className="block py-2 px-3 text-lg text-customBlue rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-customRed md:p-0">
                    Services
                  </button>
                </Link>
                <div className="dropdown-content">
                  <Link
                    className="text-white border-b-2 border-white hover:text-customRed"
                    to="/metaverse"
                  >
                    METAVERSE
                  </Link>
                  <Link
                    className="text-white border-b-2 border-white hover:text-customRed"
                    to="/artificialIntellegence"
                  >
                    ARTIFICIAL INTELLIGENCE
                  </Link>
                  <Link
                    className="text-white border-b-2 border-white hover:text-customRed"
                    to="/webdev"
                  >
                    WEB DEVELOPMENT
                  </Link>
                  <Link
                    className="text-white hover:text-customRed"
                    to="/appdev"
                  >
                    MOBILE APP DEVELOPMENT
                  </Link>
                </div>
              </div>
              <li>
                <Link
                  to="/portfolio"
                  className="block py-2 px-3 text-lg text-customBlue rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-customRed md:p-0"
                >
                  Portfolio
                </Link>
              </li>
              <li>
                <Link
                  to="/blog"
                  className="block py-2 px-3 text-lg text-customBlue  rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-customRed md:p-0"
                >
                  Blog
                </Link>
              </li>
              <li>
                <Link
                  to="/contactUs"
                  className="block py-2 px-3 text-lg text-customBlue rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-customRed md:p-0 md:dark:hover:text-blue-500"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
