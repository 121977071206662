import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const AppContent = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      offset: 200,
    });
  }, []);
  return (
    <>
      <div className="max-w-[1140px] mx-auto p-4 pt-24">
        <div>
          <h1
            data-aos="fade-right"
            className="text-3xl font-bold duration-1000"
          >
            Best Cross Platform Mobile App Development Services
          </h1>
          <div
            data-aos="fade-right"
            className="border-b-4 border-black w-[60px] "
          ></div>
          <p data-aos="fade-right" className="py-10 text-gray-500 duration-200">
            We aim to develop scalable mobile applications for our customers.
            There is a margin in the architecture of the application for future
            addition of features and protocols. Just because an app has been
            fully built doesn’t mean the process of feature addition ends. We
            develop mobile apps that have the capability of additional features
            being added into them. If you want to add more features in the
            future due to the competitive nature of the market then we will
            enable you to do so easily. Our objective is to deliver the best
            performance with scalable apps. It helps us build state of the art
            mobile app development services for our clients.
          </p>
        </div>
      </div>
      <div className="bg-customlighGray">
        <div>
          <div className="max-w-[1140px] mx-auto p-4 py-24">
            <div>
              <h1
                data-aos="fade-right"
                className="text-3xl font-bold duration-1000"
              >
                Drive Fascinating User Experiences
              </h1>
              <div
                data-aos="fade-right"
                className="border-b-4 border-black w-[60px] "
              ></div>
              <p
                data-aos="fade-right"
                className="py-5 text-gray-500 duration-100"
              >
                We take an approach that puts the concern of the user first at
                SpaceX. Our software development process provides the best user
                experience and remarkable interfaces. The primary concern while
                designing or developing a software application is the way users
                interact with it. Considering it the most valuable aspect of
                development, we strategically put our main focus on it. The idea
                behind an engaging user experience is to retain the user on your
                app and make them purchase your product. We believe in providing
                the best user experience keeping in mind the varying skill set
                of the users. The mobile apps we develop are user-friendly,
                catering to all kinds of users. Let us help you build apps that
                stand out in the competitive landscape.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppContent;
