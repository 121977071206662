import Blockchain from "../Components/Blockchain";
import BlockchainDev from "../Components/BlockchainDev";
import Footer from "../Components/Footer";
import HomeContent from "../Components/HomeContent";
import HomeImg from "../Components/HomeImg";
import IndustryTalk from "../Components/IndustryTalk";
import MobileApps from "../Components/MobileApps";
import Navbar from "../Components/Navbar";
import ScrollUp from "../Components/ScrollUp";
import ServicesCards from "../Components/ServicesCards";
import Webdevelopment from "../Components/Webdevelopment";

const HomePage = () => {
  return (
    <div>
      <Navbar />
      <HomeImg />
      <div className="zIndex-1000">
        <ServicesCards />
        <HomeContent />
        <Blockchain />
        <BlockchainDev />
        <Webdevelopment />
        <MobileApps />
        <IndustryTalk />
        <Footer />
        <ScrollUp />
      </div>
    </div>
  );
};

export default HomePage;
