import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

interface ChooseSpaceXProps {
  title: string;
  paragraphs: string[];
}

const ChooseSpaceX: React.FC<ChooseSpaceXProps> = ({ title, paragraphs }) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      offset: 200,
    });
  }, []);
  return (
    <div className="max-w-[1140px] mx-auto p-4 pt-20 pb-10">
      <div>
        <h1 className="text-3xl font-bold">{title}</h1>
        <div
          data-aos="fade-right"
          className="border-b-4 border-black w-[60px] mb-6"
        ></div>
        {paragraphs.map((paragraph, index) => (
          <p key={index} className="text-gray-500 py-2">
            {paragraph}
          </p>
        ))}
      </div>
    </div>
  );
};

export default ChooseSpaceX;
