import { useEffect } from "react";
import { IoChevronForward } from "react-icons/io5";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const WebDevContent = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      offset: 200,
    });
  }, []);
  return (
    <div className="bg-customlighGray">
      <div className="max-w-[1140px] mx-auto p-4 pt-20">
        <div>
          <h1 className="text-3xl font-bold">Why Invest in Web Development?</h1>
          <div data-aos="fade-right" className="border-b-4 border-black w-[60px] "></div>
        </div>
        <div>
          <div className="flex py-5">
            <IoChevronForward className="text-customRed w-[60px]" size={30} />
            <p className="text-gray-500">
              It helps build credibility in your brand. A good website serves as
              the starting point for any potential customer to take your brand
              seriously. When a customer is looking to work with you, they will
              first go over your website to get to know your business and all it
              offers. Therefore, it becomes crucial to invest in web development
              to build a positive image of your brand and give it credibility.
            </p>
          </div>
          <div className="flex py-2">
            <IoChevronForward className="text-customRed w-[60px]" size={30} />
            <p className="text-gray-500">
              If you want to retain prospective customers, it is important to
              build a website that is easy-to-use, easy to navigate, and offers
              a good user experience. This is what helps retain customers in the
              long run, and customer retention is extremely important to a
              business. Websites also help companies establish a unique identity
              and set themselves apart from their competitors.
            </p>
          </div>
          <div className="flex py-2">
            <IoChevronForward className="text-customRed w-[60px]" size={30} />
            <p className="text-gray-500">
              An online presence helps your brand become more accessible to
              clients from all across the globe. Now people from all over the
              world can have access to your brand and easily contact you should
              there be any queries. You could lose a large chunk of your
              customer base if you are not investing in an established website.
              A website helps people from all over the world to discover your
              brand and its services.
            </p>
          </div>
          <div className="flex py-2">
            <IoChevronForward className="text-customRed w-[40px]" size={30} />
            <p className="text-gray-500">
              If your website meets all the requirements of the Google search
              algorithm, then your website can easily get ranked at the top of
              the search results granting enhanced visibility for your brand.
              Your potential customers will easily be able to find you this way.
            </p>
          </div>
          <div className="flex py-2">
            <IoChevronForward className="text-customRed w-[60px]" size={30} />
            <p className="text-gray-500">
              A well-designed website also boosts your conversion rate. With the
              help of customs website design services, developers will continue
              upgrading the website to enable smooth functionality for the user.
              This ease of use and seamless experience would encourage the
              customer to stay on your website for longer, hence turning those
              visitors from leads to actual customers.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebDevContent;
