import MircoSoftTeam from "../Assets/Images/MicrosoftTeams.png";

const AboutContent = () => {
  return (
    <div>
      <div className="max-w-[1140px] mx-auto mt-28 pt-24 p-4">
        <div className="grid lg:grid-cols-2 place-items-center gap-7 md:grid-cols-1">
          <div>
            <img src={MircoSoftTeam} alt="" />
          </div>
          <div className="p-4">
            <h1 className="text-5xl font-bold sm:leading-10 text-black">
              Why Choose SpaceX?
            </h1>
            <h1 className="text-3xl font-bold sm:leading-10 text-black py-2">
              We Are Technical Innovators
            </h1>
            <h1 className="text-xl font-bold sm:leading-10 text-black">
              Founded in 2023, Head quarters:San Jose, California
            </h1>
            <p className="py-4 text-lg text-gray-500">
              At SpaceX, we’re dedicated to propelling your business into the
              future through cutting-edge digital transformation solutions. By
              harnessing the power of state-of-the-art technologies like
              blockchain and artificial intelligence, we don’t just reinvent
              your business – we revolutionize it. Our tailored blockchain
              development solutions are designed to align seamlessly with your
              goals, enabling you to meet the ever-growing demands of the modern
              business landscape while gaining a distinct competitive advantage.
              With OptimusFox by your side, the possibilities are limitless as
              we pave the way for your success in the digital age.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutContent;
