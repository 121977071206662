import "../Css/Main.css";
import mobileapp from "../Assets/Images/AppImage.png";
import curveImg from "../Assets/Images/Curve.svg";
import { Link } from "react-router-dom";

const AppDevHome = () => {
  return (
    <div className="bg-black pt-10 relative">
      <div className="w-[100%] absolute bg-customlighGray bottom-0">
        <img className="w-[100%]" src={curveImg} alt="" />
      </div>
      <div className="max-w-[1240px] mx-auto py-40 p-4">
        <div className="grid lg:grid-cols-2 gap-10 place-items-center">
          <div>
            <h1 className="lg:text-3xl md:text-3xl max-sm:text-[34px] font-extrabold text-white">
              MOBILE APP DEVELOPMENT SERVICES BY OPTIMUSFOX
            </h1>
            <p className="text-xl py-5 text-white">
              MOptimusFox is a mobile app development firm that specializes in
              creating high-performance, feature-packed, and resilient mobile
              applications for both iOS and Android platforms.
            </p>
            <p className="text-xl text-white">
              With proficiency in developing cross-platform applications, the
              company ensures compatibility across various operating systems.
            </p>
            <Link to="/contactUs">
              <button
                type="button"
                className="text-white my-5 bg-customRed cursor-pointer focus:outline-none font-bold rounded-md text-lg px-5 py-2 hover:scale-110 transition ease-in-out text-center"
              >
                Get Free Consultation
              </button>
            </Link>
          </div>
          <div>
            <img
              className="lg:h-[380px] md:h-[480px]"
              src={mobileapp}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppDevHome;
