import { useEffect } from "react";
import "../Css/Main.css";
import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const IntellegenceContent = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      offset: 200,
    });
  }, []);
  return (
    <div className="bg-customlighGray">
      <div className="max-w-[1140px] mx-auto p-4 pt-20">
        {/* Upper Content Start */}
        <div>
          <h1 className="text-3xl font-bold">
            Advantages of Artificial Intelligence to Companies
          </h1>
          <div
            data-aos="fade-right"
            className="border-b-4 border-black w-[60px] "
          ></div>
        </div>
        <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-4 mt-10">
          <div className="flex">
            <div>
              <FaCheckCircle size={25} className="text-customRed" />
            </div>
            <div className="ps-3">
              <h1 className="text-black text-2xl font-bold">
                Efficiency & Productivity
              </h1>
              <p className="text-gray-500 py-3">
                AI technology can perform tasks faster and with more efficiency,
                something that is beyond the scope of humans. By removing these
                tasks from humans, AI enables them to move towards tasks that
                are only capable of being performed by humans and not something
                that technology can do. This helps companies significantly
                reduce mundane and repetitive tasks that can be easily performed
                with the help of technology and therefore utilize their human
                capital.
              </p>
            </div>
          </div>
          <div className="flex">
            <div>
              <FaCheckCircle size={25} className="text-customRed" />
            </div>
            <div className="ps-3">
              <h1 className="text-black text-2xl font-bold">
                Excellent Customer Service
              </h1>
              <p className="text-gray-500 py-3">
                Customer satisfaction is the utmost priority to companies. But,
                companies normally don’t have a salesperson who has the combined
                knowledge of the customer, the customer’s needs, the company’s
                solutions, and the competition and then presents to the customer
                whatever and whenever they need whenever it. This is where AI
                comes in. It can help organizations deliver customized and
                interpersonal interactions between themselves and the customer,
                which would give them a greater insight into the customer’s
                needs and therefore improve the customer experience.
              </p>
            </div>
          </div>
          <div className="flex">
            <div>
              <FaCheckCircle size={25} className="text-customRed" />
            </div>
            <div className="ps-3">
              <h1 className="text-black text-2xl font-bold">
                Improved Monitoring
              </h1>
              <p className="text-gray-500 py-3">
                Artificial Intelligence can take in and process a huge amount of
                data at a time which allows organizations to implement extensive
                monitoring that can alert them of any issues and recommend any
                actions needed. AI has monitoring capabilities that can also be
                effectively utilized in other areas such as cybersecurity
                operations where massive amounts of data need to be analyzed and
                understood.
              </p>
            </div>
          </div>
          <div className="flex">
            <div>
              <FaCheckCircle size={25} className="text-customRed" />
            </div>
            <div className="ps-3">
              <h1 className="text-black text-2xl font-bold">Improved Speed</h1>
              <p className="text-gray-500 py-3">
                Businesses are moving at a rapid pace owing to the advancement
                in technology. Artificial intelligence helps shorten as well as
                speed up development cycles. This shortened timeline also helps
                with better project deliveries, which enhances your return on
                investment.
              </p>
            </div>
          </div>
          <div className="flex">
            <div>
              <FaCheckCircle size={25} className="text-customRed" />
            </div>
            <div className="ps-3">
              <h1 className="text-black text-2xl font-bold">
                High Quality & Reduction of Errors
              </h1>
              <p className="text-gray-500 py-3">
                Businesses can greatly benefit from incorporating Artificial
                Intelligence as less human interference leads to a reduction of
                errors and overall smooth business processes. When AI is
                integrated with other technologies like RPA, repetitive and
                rule-based tasks are automated. This not only speeds up
                processes but reduces errors and takes on a wider range of tasks
                for high-quality results. Thus, AI has not compromised quality,
                speed, and cost.
              </p>
            </div>
          </div>
        </div>
        {/* Upper Content End */}

        {/* LOWER CONTENT START */}
        <div className="mt-20 mb-20">
          <h1 className="text-3xl font-bold">Our AI Development Services</h1>
          <div data-aos="fade-right" className="border-b-4 border-black w-[60px] "></div>
          <p className="text-gray-500 py-5">
            We offer a wide range of services with the most innovative
            Artificial Intelligence solutions to help your brand achieve
            success.
          </p>
        </div>
        {/* CARDS START */}
        <div className="grid lg:grid-cols-3 md:grid-cols-3 gap-5 max-sm:place-items-center">
          <div className="max-w-sm p-6 bg-white border shadow">
            <h5 className="mb-2 pt-10 text-[20px] font-bold tracking-tight text-black text-center">
              Robotic Process Automation
            </h5>
            <p className="mb-3 font-normal text-black text-center text-md">
              We revolutionize your business processes and eliminate tedious
              tasks.
            </p>
            <Link
              to="/services"
              className="flex items-center px-3 py-5 pb-10 text-sm font-bold text-center text-black justify-center"
            >
              Read more
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </Link>
          </div>
          <div className="max-w-sm p-6  bg-white border shadow">
            <h5 className="mb-2 pt-10 text-[20px] font-bold tracking-tight text-black text-center">
              Big Data
            </h5>
            <p className="mb-3 font-normal text-black text-center text-md">
              We solve your data management issues effectively with the use of
              Big Data.
            </p>
            <Link
              to="/services"
              className="flex items-center px-3 py-5 pb-10 text-sm font-bold text-center text-black justify-center"
            >
              Read more
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </Link>
          </div>
          <div className="max-w-sm p-6  bg-white border shadow">
            <h5 className="mb-2 pt-10 text-[20px] font-bold tracking-tight text-black text-center">
              Natural Language Processing
            </h5>
            <p className="mb-3 font-normal text-black text-center text-md">
              We offer NLP services using a combination of AI, Machine Learning
              (ML), and Linguistics.
            </p>
            <Link
              to="/services"
              className="flex items-center px-3 py-5 pb-10 text-sm font-bold text-center text-black justify-center"
            >
              Read more
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </Link>
          </div>
        </div>
        {/* CARDS END */}
        {/* IMAGE CONTENT START */}
        <div className="my-14 overlay-bg py-14 p-8">
          <div className="grid lg:grid-cols-2 md:grid-cols-2 max-sm:place-items-center gap-10">
            <div>
              <h1 data-aos="fade-up" className="text-white text-3xl font-bold">
                Automate your business processes and achieve growth
              </h1>
            </div>
            <div className="flex justify-end items-center">
              <Link to="/contactUs">
                <button
                  type="button"
                  className="text-white bg-customRed cursor-pointer focus:outline-none font-bold rounded-md text-sm px-7 py-3 text-center hover:scale-110 transition ease-in-out"
                >
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
        </div>
        {/* IMAGE CONTENT END */}
        {/* TEXT PROCESS START */}
        <div className="my-5">
          <div>
            <h1 className="text-3xl font-bold">Our AI Processes</h1>
            <div
              data-aos="fade-right"
              className="border-b-4 border-black w-[60px]"
            ></div>
          </div>
          <div data-aos="fade-down" className="grid lg:grid-cols-2 md:grid-cols-2 gap-5 pt-10">
            <div className="flex">
              <div>
                <FaCheckCircle size={25} className="text-customRed" />
              </div>
              <div className="ps-3">
                <h1 className="text-black text-2xl font-bold">
                  Defining Your Goals
                </h1>
                <p className="text-gray-500 py-3">
                  We begin by formulating a unique strategy. This is needed to
                  optimize your business by identifying the places where
                  Artificial Intelligence Development can add value to your
                  organization.
                </p>
              </div>
            </div>
            <div className="flex">
              <div>
                <FaCheckCircle size={25} className="text-customRed" />
              </div>
              <div className="ps-3">
                <h1 className="text-black text-2xl font-bold">Execution</h1>
                <p className="text-gray-500 py-3">
                  We bring in the technical expertise required to bring your AI
                  applications to life. This is specifically catered to
                  customers who are looking for AI-driven projects.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* TEXT PROCESS END */}
      </div>
    </div>
  );
};

export default IntellegenceContent;
