import "../Css/Main.css";
import metaverseImg from "../Assets/Images/Microsoft-Metaverse.png";
import curveImg from "../Assets/Images/Curve.svg";
import { Link } from "react-router-dom";

const MetaverseHome = () => {
  return (
    <>
      <div className="bg-black pt-10 relative">
        <div className="w-[100%] absolute bg-customlighGray bottom-0">
          <img className="w-[100%]" src={curveImg} alt="" />
        </div>
        <div className="max-w-[1240px] mx-auto py-40 p-4">
          <div className="grid lg:grid-cols-2 gap-10 place-items-center">
            <div>
              <h1 className="lg:text-4xl md:text-4xl max-sm:text-[34px] font-extrabold text-white">
                CONNECT TO THE INNOVATIONAL WORLD OF METAVERSE
              </h1>
              <p className="text-xl font-bold text-white">
                Metaverse brings the future you need.
              </p>
              <Link to="/contactUs">
                <button
                  type="button"
                  className="text-white my-3 bg-customRed cursor-pointer focus:outline-none font-bold rounded-md text-lg px-9 py-4 hover:scale-110 transition ease-in-out text-center"
                >
                  Talk to Our Experts
                </button>
              </Link>
            </div>
            <div>
              <img
                className="lg:h-[490px] md:h-[490px]"
                src={metaverseImg}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MetaverseHome;
