import Navbar from '../Components/Navbar'
import PortFolioContent from '../Components/PortFolioContent'
import Footer from '../Components/Footer'

const Portfolio = () => {
  return (
    <div>
      <Navbar/>
      <PortFolioContent/>
      <Footer/>
    </div>
  )
}

export default Portfolio
