import GroupImg from "../Assets/Images/Group.svg";

const HomeContent = () => {
  return (
    <div className="max-w-[1140px] mx-auto my-28 p-4">
      <div className="grid lg:grid-cols-2 gap-10 md:grid-cols-1">
        <div>
          <img src={GroupImg} alt="" />
        </div>
        <div className="p-4">
          <h1 data-aos="fade-left" className="text-4xl font-bold sm:leading-10">
            <span className="border-b-4 border-black">Why</span> Choose
            SpaceX?
          </h1>
          <p className="py-6 text-lg text-gray-500">
            As a blockchain development company, we make use of top-notch
            solutions to digitally transform your business and take it to new
            heights.
          </p>
          <p className="py-6 text-lg text-gray-500">
            Transforming your Web3 ideas into tangible reality is our expertise.
            We are committed to delivering quality solutions at every stage of
            the process. With a deep understanding of blockchain-based software
            development, we are dedicated to providing innovative solutions
            tailored to your specific business requirements.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeContent;
