import Navbar from "../Components/Navbar";
import AppDevHome from "../Components/AppDevHome";
import AppDevCards from "../Components/AppDevCards";
import AppContent from "../Components/AppContent";
import Accordians from "../Components/Accordians";
import Footer from "../Components/Footer";
import ScrollUp from "../Components/ScrollUp";

const AppDev = () => {
  const accordionItems = [
    {
      id: "1",
      question: "We Build Native And Cross-Platform Applications",
      answer:
        "We are offering mobile app development services in both domains, iOS and Android. Our experienced team of mobile app developers are competent in developing both native and cross-platform applications for your business. OptimusFox develops mobile apps that has a wide array of features.",
    },
    {
      id: "2",
      question: "Mobile App Development Services For Scalable ResultsF",
      answer:
        "Our highly skilled mobile application development team develops system architectures that guarantee optimal working of the mobile applications. App development is done by high performing teams that use the latest software development frameworks. Following the latest technology trends, we build apps with excellent performance.",
    },
    {
      id: "3",
      question: "We Guarantee Data Security",
      answer:
        "Security is a primary concern for users. As such, security is a major factor that we never compromise on when it comes to the apps we develop. As a mobile application development company, Optimus Fox ensures security at every level of  the development process right from the beginning to the last step. With lots of data in the Cloud as well as data centers of Google facility, people are concerned about their personal data. We are aware of these growing concerns and hence put our primary focus on the security of your data. We provide applications that are highly secure. ",
    },
  ];

  return (
    <div>
      <Navbar />
      <AppDevHome />
      <AppDevCards />
      <AppContent />
      <Accordians items={accordionItems} />
      <Footer/>
      <ScrollUp/>
    </div>
  );
};

export default AppDev;
