import Logo from "../Assets/Images/SpaceX.png";
import { FaRegWindowMinimize } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  FaLinkedin,
  FaInstagram,
  FaFacebook,
  FaYoutube,
  FaTwitter,
  FaPhoneVolume,
} from "react-icons/fa";
import { CiMail, CiLocationOn } from "react-icons/ci";

const Footer = () => {
  const currentYear = new Date().getFullYear(); // Get the current year
  return (
    <div className="conatiner pt-10 pb-10  bg-black">
      <div className="footer-bg-img">
        <div className="max-w-[1140px] mx-auto py-20 text-white p-4">
          <div className="grid lg:grid-cols-4 md:grid-cols-2 md:justify-center  gap-y-10 gap-x-5">
            <div>
              <img src={Logo} alt="" className="h-24" />
              <p className="pt-10 text-md">
                At SpaceX, you can digitally transform your business with our
                top-notch solutions. We offer blockchain development solutions
                to reinvent.
              </p>
              <h1 className="text-3xl font-bold py-2">FOLLOW US</h1>
              <div className="text-white flex items-center">
                <div className="px-2">
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/spacexpakistan/"
                  >
                    <FaLinkedin size={30} />
                  </a>
                </div>
                <div className="px-2">
                  <a href="">
                    <FaInstagram size={30} />
                  </a>
                </div>
                <div className="px-2">
                  <a href="">
                    <FaFacebook size={30} />
                  </a>
                </div>
                <div className="px-2">
                  <a href="">
                    <FaTwitter size={30} />
                  </a>
                </div>
                <div className="px-2">
                  <a href="">
                    <FaYoutube size={30} />
                  </a>
                </div>
              </div>
              <div className="pt-10 text-sm font-bold">
                © {currentYear} SpaceX. All Rights Reserved.
              </div>
            </div>
            <div>
              <h1 className="text-3xl font-bold">OUR SERVICES</h1>
              <ul>
                <div className="flex pt-4">
                  <FaRegWindowMinimize className="text-customRed" />
                  <li className="cursor-pointer pl-3">
                    <Link to="/metaverse">Metaverse</Link>
                  </li>
                </div>
                <div className="flex pt-4">
                  <FaRegWindowMinimize className="text-customRed" />
                  <li className="cursor-pointer pl-3">
                    <Link to="/artificialIntellegence">
                      Artificial Intellegence{" "}
                    </Link>
                  </li>
                </div>
                <div className="flex pt-4">
                  <FaRegWindowMinimize className="text-customRed" />
                  <li className="cursor-pointer pl-3">
                    <Link to="/webdev">Web development</Link>
                  </li>
                </div>
                <div className="flex pt-4">
                  <FaRegWindowMinimize className="text-customRed" />
                  <li className="cursor-pointer pl-3">
                    <Link to="/appdev">Mobile App Development</Link>
                  </li>
                </div>
              </ul>
            </div>
            <div>
              <h1 className="text-3xl font-bold">QUICK LINKS</h1>
              <ul>
                <div className="flex pt-4">
                  <FaRegWindowMinimize className="text-customRed" />
                  <li className="cursor-pointer pl-3">
                    <Link to="/about">About Us</Link>
                  </li>
                </div>
                <div className="flex pt-4">
                  <FaRegWindowMinimize className="text-customRed" />
                  <li className="cursor-pointer pl-3">
                    <Link to="/services">Services</Link>
                  </li>
                </div>
                <div className="flex pt-4">
                  <FaRegWindowMinimize className="text-customRed" />
                  <li className="cursor-pointer pl-3">
                    <Link to="/blog">Blog</Link>{" "}
                  </li>
                </div>
                <div className="flex pt-4">
                  <FaRegWindowMinimize className="text-customRed" />
                  <li className="cursor-pointer pl-3">
                    <Link to="/contactUs">Contact Us</Link>{" "}
                  </li>
                </div>
              </ul>
            </div>
            <div>
              <h1 className="text-3xl font-bold">CONTACT</h1>
              <ul>
                <div className="flex pt-4">
                  <FaPhoneVolume className="text-customRed" />
                  <li className="cursor-pointer pl-3">+92-334-4403514</li>
                </div>
                <div className="flex pt-4">
                  <FaPhoneVolume className="text-customRed" />
                  <li className="cursor-pointer pl-3">+92-300-8640889</li>
                </div>
                <div className="flex pt-4">
                  <CiMail className="text-customRed" />
                  <li className="cursor-pointer pl-3">info@spacexpakistan.com</li>
                </div>
                <div className="flex pt-4">
                  <CiLocationOn className="text-customRed" />
                  <li className="cursor-pointer pl-3">
                    Founded in 2023 <br /> Head quarters:San Jose, California{" "}
                    <br /> Local Branch:Trade <br /> Center Gujranwala,Pakistan
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
