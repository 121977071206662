import MobileAppImg from "../Assets/Images/Mobile-App-Development.svg";
import { GrSwift } from "react-icons/gr";
import { TbBrandKotlin, TbBrandFlutter } from "react-icons/tb";
import { FaReact } from "react-icons/fa6";

const MobileApps = () => {
  return (
    <div>
      <div className="max-w-[1140px] mx-auto p-4">
        <div className="grid lg:grid-cols-2 py-10 items-center">
          <div data-aos="fade-right">
            <img src={MobileAppImg} alt="" />
          </div>
          <div>
            <h1 data-aos="fade-left" className="text-4xl font-bold lg:pt-16">
              <span className="border-b-4 border-black">Mob</span>ile Apps
              Development
            </h1>
            <p className="py-7 text-gray-400">
              We have a team of experts developing feature-rich applications for
              iOS and Android
            </p>
            <div className="grid lg:grid-cols-3 md:grid-cols-2  gap-5">
              <div className="flex items-center justify-center flex-col">
                <GrSwift size={70} className="text-customRed" />
                <p className="text-gray-400 py-4">Swift</p>
              </div>
              <div className="flex items-center justify-center flex-col">
                <TbBrandKotlin size={70} className="text-customRed" />
                <p className="text-gray-400 py-4">Kotlin</p>
              </div>
              <div className="flex items-center justify-center flex-col">
                <FaReact size={70} className="text-customRed" />
                <p className="text-gray-400 py-4">React Native</p>
              </div>
              <div className="flex items-center justify-center flex-col">
                <TbBrandFlutter size={70} className="text-customRed" />
                <p className="text-gray-400 py-4">Flutter</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileApps;
