import blockchaindevelopment from "../Assets/Images/Blockchain-Develeopment.svg";
import Ethereum from "../Assets/Images/etherium.svg";
import Solana from "../Assets/Images/Solana.svg";
import Cardano from "../Assets/Images/caradano.svg";
import Hyperledger from "../Assets/Images/Hyerledger.svg";
import Binance from "../Assets/Images/Binance.svg";
import Thorchain from "../Assets/Images/Thorchain-1.svg";
import { Link } from "react-router-dom";

const BlockchainDev = () => {
  return (
    <>
      <div className="max-w-[1140px] mx-auto my-16 p-4">
        <h1 className="text-4xl font-bold">
          <span className="border-b-4 border-black">The</span> Technologies We
          Work With
        </h1>
        <p className="py-10 text-gray-400">
          We use the latest technological solutions to offer you premium, high
          value services.
        </p>
        <div className="grid lg:grid-cols-2 py-10 items-center">
          <div data-aos="fade-up">
            <img src={blockchaindevelopment} alt="" />
          </div>
          <div>
            <h1 className="text-4xl font-bold lg:pt-16">
              <span className="border-b-4 border-black">Blo</span>ckchain
              Development
            </h1>
            <p className="py-7 text-gray-400">
              We use the latest technological solutions to offer you premium,
              high value services.
            </p>
            <div className="grid lg:grid-cols-3 md:grid-cols-2  gap-5">
              <div className="flex items-center justify-center flex-col">
                <img className="w-16" src={Ethereum} alt="" />
                <p className="text-gray-400 py-4">Ethereum</p>
              </div>
              <div className="flex items-center justify-center flex-col">
                <img className="w-16" src={Solana} alt="" />
                <p className="text-gray-400 py-4">Solana</p>
              </div>
              <div className="flex items-center justify-center flex-col">
                <img className="w-16" src={Cardano} alt="" />
                <p className="text-gray-400 py-4">Cardano</p>
              </div>
              <div className="flex items-center justify-center flex-col">
                <img className="w-16" src={Hyperledger} alt="" />
                <p className="text-gray-400 py-4">Hyperledger</p>
              </div>
              <div className="flex items-center justify-center flex-col">
                <img className="w-16" src={Binance} alt="" />
                <p className="text-gray-400 py-4">Binance</p>
              </div>
              <div className="flex items-center justify-center flex-col">
                <img className="w-16" src={Thorchain} alt="" />
                <p className="text-gray-400 py-4">Thorchain</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-black text-white py-2">
        <div className="max-w-[1140px] mx-auto my-16 p-4">
          <div className="grid lg:grid-cols-2 gap-5 place-items-center">
            <div>
              <h1 data-aos="fade-right" className="text-3xl font-bold">
                Integrate your business with our dynamic software solutions
              </h1>
            </div>
            <div>
              <Link to="/contactUs">
                <button className="bg-customRed hover:scale-110 transition ease-in-out text-white font-bold py-2 px-8 rounded items-end">
                  Talk to Our Expert
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlockchainDev;
