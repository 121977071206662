import { useEffect } from "react";
import "../Css/Main.css";

import { Link } from "react-router-dom";
import { TbBrandFlutter } from "react-icons/tb";
import { FaReact } from "react-icons/fa6";
import { RiAppleLine } from "react-icons/ri";
import robotsvg from "../Assets/Images/Robote.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const AppDevCards = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      offset: 200,
    });
  }, []);
  return (
    <div>
      <div className="bg-customlighGray">
        <div className="max-w-[1140px] mx-auto p-4 pt-20">
          <div>
            <h1
              data-aos="fade-right"
              className="text-3xl font-bold duration-500"
            >
              How Can SpaceX Help You?
            </h1>
            <div
              data-aos="fade-right"
              className="border-b-4 border-black w-[60px] "
            ></div>
            <p data-aos="fade-right" className="text-gray-500 py-4">
              Since we have the expertise in both native and cross-platform
              technologies, we always keep a balance between the price, quality,
              and requirements of the client so that we can offer the best
              solution to their specific problems. We aim to deliver
              applications without glitches, bugs and to provide a stellar user
              experience with our design strategy to engage large audiences.
            </p>
            <p data-aos="fade-right" className="text-gray-500 ">
              We are one of the top mobile app development company in the USA
              that are dedicated to delivering maximum output to the clients in
              both iOS and Android technology. We offer cost-effective solutions
              that make us a profitable choice for clients. Hire us to build
              apps that stand out.{" "}
            </p>
          </div>
          <div
            data-aos="fade-down"
            data-aos-duration="1000"
            className="grid grid-cols-2 gap-5 mt-10 animation-down"
          >
            <div className="p-6  bg-white shadow-2xl">
              <div className="flex justify-center py-12 px-20">
                <RiAppleLine size={50} className="text-customRed" />
              </div>
              <h5 className="mb-2 text-[18px] font-bold text-black text-center">
                iOS App Development
              </h5>
              <p className="mb-3 font-normal text-gray-500 text-center text-md">
                We build futuristic iOS applications in both Swift and
                Objective-C as per the requirements of our clients.
              </p>
            </div>
            <div className="p-6  bg-white shadow-2xl">
              <div className="flex justify-center py-12 px-20">
                <img className="h-[50px]" src={robotsvg} alt="" />
              </div>
              <h5 className="mb-2 text-[18px] font-bold text-black text-center">
                Android App Development
              </h5>
              <p className="mb-3 font-normal text-gray-500 text-center text-md">
                Our mobile application development team is equipped with the
                complete knowledge and expertise on Android app development.
              </p>
            </div>
          </div>
          <div
            data-aos="fade-down"
            data-aos-duration="1000"
            className="grid grid-cols-2 gap-5 mt-5 animation-down"
          >
            <div className="p-6  bg-white shadow-2xl">
              <div className="flex justify-center py-12 px-20">
                <TbBrandFlutter size={50} className="text-customRed" />
              </div>
              <h5 className="mb-2 text-[18px] font-bold text-black text-center">
                Flutter Development
              </h5>
              <p className="mb-3 font-normal text-gray-500 text-center text-md">
                SpaceX is a leading flutter app development company aiming to
                empower businesses across the globe with flutter applications.
              </p>
            </div>
            <div className="p-6  bg-white shadow-2xl">
              <div className="flex justify-center py-12 px-20">
                <FaReact size={50} className="text-customRed" />
              </div>
              <h5 className="mb-2 text-[18px] font-bold text-black text-center">
                React Native
              </h5>
              <p className="mb-3 font-normal text-gray-500 text-center text-md">
                Empower your applications through user-friendly, responsive, and
                compelling UI/UX designs. We have a team of expert React Native
                developers
              </p>
            </div>
          </div>

          {/* IMAGE CONTENT START */}
          <div className="my-14 overlay-bg py-14 p-8">
            <div className="grid lg:grid-cols-2 md:grid-cols-2 max-sm:place-items-center gap-10">
              <div data-aos="fade-up" data-aos-duration="1000">
                <h1 className="text-white text-3xl font-bold">
                  Creating applications that deliver value
                </h1>
              </div>
              <div className="flex justify-end items-center">
                <Link to="/contactUs">
                  <button
                    type="button"
                    className="text-white bg-customRed cursor-pointer focus:outline-none font-bold rounded-md text-sm px-7 py-3 text-center hover:scale-110 transition ease-in-out"
                  >
                    Talk to Our Experts
                  </button>
                </Link>
              </div>
            </div>
          </div>
          {/* IMAGE CONTENT END */}
        </div>
      </div>
    </div>
  );
};

export default AppDevCards;
