import Onshore from "../Assets/Images/On-Shore.png";
import Offshore from "../Assets/Images/Off_Shore.png";
import Hybrid from "../Assets/Images/Hyperledger.png";
import MutlipleBoxes from "../Assets/Images/Home-Blockchain.jpg";

const Blockchain = () => {
  return (
    <div className="bg-black text-white blockchain-bg-img ">
      <div className="max-w-[1140px] mx-auto p-4">
        <h1 className="text-4xl font-bold pt-16">
          <span className="border-b-4 border-white">Blo</span>ckchain Software
          Development Outsourcing
        </h1>
        <div className="grid place-items-center lg:grid-cols-2 md:grid-cols-1 gap-6 py-28">
          <div>
            <div className="flex">
              <div>
                <img className="w-44" src={Onshore} alt="" />
              </div>
              <div className="pl-6">
                <p className="text-2xl font-bold">
                  Onshore Or Local Outsourcing
                </p>
                <p className="pt-7">
                  Onshore outsourcing enables ease of communication, significant
                  cost savings and effective quality control.
                </p>
              </div>
            </div>
            <div className="flex py-20">
              <div>
                <img className="w-72" src={Offshore} alt="" />
              </div>
              <div className="pl-6">
                <p className="text-2xl font-bold">Off-Shore Outsourcing</p>
                <p className="pt-7">
                  Offshore outsourcing enables more efficiency by allowing
                  skilled resources to work on your projects leaving the menial
                  tasks to third parties while you focus on the core of the
                  business and what really matters.
                </p>
              </div>
            </div>
            <div className="flex">
              <div>
                <img className="w-72" src={Hybrid} alt="" />
              </div>
              <div className="pl-6">
                <p className="text-2xl font-bold">Hybrid Outsourcing</p>
                <p className="pt-7">
                  Hybrid outsourcing offers a blend of both onshore and offshore
                  outsourcing offering greater flexibility, lower costs and a
                  single source of contact and management so that everything can
                  be managed effectively.
                </p>
              </div>
            </div>
          </div>
          <div data-aos="fade-right">
            <img className="rounded-2xl h-[340px] " src={MutlipleBoxes} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blockchain;
