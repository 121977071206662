import AppFutura from "../Assets/Images/App-Futura.png";
import GoodFilms from "../Assets/Images/Good-Firms.png";
import GlobalImg from "../Assets/Images/G2.png";
import ClutchImg from "../Assets/Images/clutch.png";
import BarkImg from "../Assets/Images/bark.png";
import SimpleSlider from "./SimpleSlider";

const IndustryTalk = () => {
  return (
    <div>
      <div className="max-w-[1140px] mx-auto mb-20 p-4">
        <div>
          <div>
            <h1 className="text-3xl font-bold lg:pt-16">
              <span className="border-b-4 border-black">What</span> the Industry
              Talks About Us
            </h1>
            <SimpleSlider/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustryTalk;
