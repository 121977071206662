import React, { useState } from "react";
import "../Css/Main.css";

interface FormData {
  name: string;
  email: string;
  phone: string;
  subject: string;
  message: string;
}

interface Errors {
  name?: string;
  email?: string;
  phone?: string;
  subject?: string;
  message?: string;
}

const ContactForm = () => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState<Errors>({});

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let newErrors: Errors = {};

    if (!formData.name) {
      newErrors.name = "Name is required";
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }

    if (!formData.phone) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\d{8,10}$/.test(formData.phone)) {
      newErrors.phone = "Phone number is invalid";
    }

    if (!formData.subject) {
      newErrors.subject = "Subject is required";
    }

    if (!formData.message) {
      newErrors.message = "Message is required";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // Form submission logic goes here
      console.log("Form submitted successfully");
    }
  };

  return (
    <div className=" mt-28">
      <section className="bg-white contact-background">
        <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-white">
            Send A Message
          </h2>
          <p className="mb-8 lg:mb-16 font-light text-center text-white sm:text-xl">
            Let’s work together to bring your ideas to life and launch your next
            successful project. Request a quote.
          </p>
          <form onSubmit={handleSubmit} className="space-y-8">
            <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-5">
              <div>
                <label
                  htmlFor="name"
                  className="block mb-2 text-md font-medium text-white"
                >
                  Your Name
                </label>
                <input
                  type="text"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 ${
                    errors.name ? "border-2 border-red-500" : ""
                  }`}
                  placeholder="Full Name"
                />
                {errors.name && (
                  <p className="text-customRed text-md">{errors.name}</p>
                )}
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-md font-medium text-white"
                >
                  Your email
                </label>
                <input
                  type="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 ${
                    errors.email ? "border-2 border-red-500" : ""
                  }`}
                  placeholder="Email"
                />
                {errors.email && (
                  <p className="text-customRed text-md">{errors.email}</p>
                )}
              </div>
            </div>
            <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-5">
              <div>
                <label
                  htmlFor="phone"
                  className="block mb-2 text-md font-medium text-white"
                >
                  Phone Number
                </label>
                <input
                  type="text"
                  id="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 ${
                    errors.phone ? "border-2 border-red-500" : ""
                  }`}
                  placeholder="Type your number"
                />
                {errors.phone && (
                  <p className="text-customRed text-md">{errors.phone}</p>
                )}
              </div>
              <div>
                <label
                  htmlFor="subject"
                  className="block mb-2 text-md font-medium text-white"
                >
                  Subject
                </label>
                <input
                  type="text"
                  id="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5
                  ${errors.subject ? "border-2 border-red-500" : ""}`}
                  placeholder="Let us know how we can help you"
                />
                {errors.subject && (
                  <p className="text-customRed text-md">{errors.subject}</p>
                )}
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block mb-2 text-md font-medium text-white"
              >
                Your message
              </label>
              <textarea
                id="message"
                rows={6}
                value={formData.message}
                onChange={handleChange}
                className={`block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500
                ${errors.message ? "border-2 border-red-500" : ""} `}
                placeholder="Leave a comment..."
              ></textarea>
              {errors.message && (
                <p className="text-customRed text-md">{errors.message}</p>
              )}
            </div>
            <button
              type="submit"
              className="py-3 px-5 text-md font-medium text-center text-customRed border border-customRed rounded-lg sm:w-fit hover:bg-customRed hover:text-white hover:scale-110 transition ease-in-out focus:outline-none"
            >
              Submit
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default ContactForm;
