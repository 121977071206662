import BlogContent from "../Components/BlogContent"
import Footer from "../Components/Footer"
import Navbar from "../Components/Navbar"

const Blog = () => {
  return (
    <div>
       <Navbar/>
       <BlogContent/>
       <Footer/>
    </div>
  )
}

export default Blog
