import { IoChevronForward } from "react-icons/io5";

const MetaverseContent = () => {
  return (
    <div>
      <div className="max-w-[1140px] mx-auto mt-28 pt-4 pb-24 p-4">
        <div>
          <h1 className="text-3xl font-bold">
            Our Metaverse Development Solutions
          </h1>
          <div className="border-b-4 border-black w-[70px] "></div>
          <h3 className="text-2xl font-bold py-5">Metaverse Ecosystems</h3>
          <p className="text-gray-500">
            The Metaverse is a virtual world with limitless potential. It’s an
            all-in-one solution for the future, where we can seamlessly blend
            our real and digital worlds and utilize blockchain technology to
            empower users’ creativity within the Metaverse. The system works
            with cryptocurrencies, allows immediate transactions, and provides
            scalability and security, enabling users to express themselves
            through in-game items and digital assets while creating valuable
            data points that will help us better understand human behavior in
            various contexts.{" "}
          </p>
          <div>
            <h1 className="text-2xl font-bold pt-14">
              Why Choose SpaceX for your NFTs Development to Deployment
              services?
            </h1>
            <div className="border-b-4 border-black w-[70px]"></div>
            <div className="flex py-5">
              <IoChevronForward className="text-customRed" size={30} />
              <p className="text-gray-500 text-lg">
                SpaceX is one of the best metaverse development companies in the
                USA.
              </p>
            </div>
            <div className="flex py-2">
              <IoChevronForward className="text-customRed" size={30} />
              <p className="text-gray-500 text-lg">
                Our approach is to create a new infrastructure that allows
                anyone to build scalable, flexible, and secure applications
                without <br />
                needing prior knowledge of blockchain or coding.
              </p>
            </div>
            <div className="flex py-2">
              <IoChevronForward className="text-customRed" size={30} />
              <p className="text-gray-500 text-lg">
                Our team consists of expert blockchain developers who know the
                ins and outs of Metaverse and NFTs Development. They have <br />{" "}
                years of experience in the field and are well versed with all
                blockchain protocols.
              </p>
            </div>
            <div className="flex py-2">
              <IoChevronForward className="text-customRed" size={30} />
              <p className="text-gray-500 text-lg">
                We maintain the confidentiality of your project throughout its
                duration and sign a Non-Disclosure Agreement (NDA) with you as
                well
              </p>
            </div>
            <div className="flex py-2">
              <IoChevronForward className="text-customRed" size={30} />
              <p className="text-gray-500 text-lg">
                Our team takes an agile development approach to deliver the most
                effective results so your Metaverse is guaranteed to be a
                certified success.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetaverseContent;
