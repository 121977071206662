import React from "react";
import Slider from "react-slick";
import AppFutura from "../Assets/Images/App-Futura.png";
import GoodFilms from "../Assets/Images/Good-Firms.png";
import GlobalImg from "../Assets/Images/G2.png";
import ClutchImg from "../Assets/Images/clutch.png";
import BarkImg from "../Assets/Images/bark.png";

const SimpleSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };
  return (
    <>
      <div className="max-w-[1140px] mx-auto bg-gray-500 max-sm:bg-white p-16 max-sm:p-0 mt-10">
        <div className="slider-container items-center">
          <Slider {...settings}>
            <div className="items-center">
              <img className="w-50 h-36 max-sm:w-40 max-sm:h-28 max-sm:ps-2" src={AppFutura} alt="" />
            </div>
            <div className="items-center">
              <img className="w-50 h-36 max-sm:w-40 max-sm:h-28 max-sm:ps-2" src={GoodFilms} alt="" />
            </div>
            <div className="items-center">
              <img className="w-50 h-36 max-sm:w-40 max-sm:h-28 max-sm:ps-2" src={GlobalImg} alt="" />
            </div>
            <div className="items-center">
              <img className="w-50 h-32 max-sm:w-40 max-sm:h-28 max-sm:ps-2" src={ClutchImg} alt="" />
            </div>
            <div className="items-center">
              <img className="w-50 h-36 max-sm:w-40 max-sm:h-28 max-sm:ps-2" src={BarkImg} alt="" />
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};

export default SimpleSlider;
